export type Gsp = number;

export interface GspDataRow {
  timestamp: number;
  averageGsp: Gsp;
  maxGsp: Gsp;
}

// This data is automatically generated from `update-gsp.js`. Do not manually
// edit.
export const ELITE_GSP_DATA: GspDataRow[] = [
  // START DATA
  { timestamp: 1585626637737, averageGsp: 6657873, maxGsp: 6899350 },
  { timestamp: 1585634452911, averageGsp: 6656870, maxGsp: 6898311 },
  { timestamp: 1585663262769, averageGsp: 6651632, maxGsp: 6892883 },
  { timestamp: 1585692072792, averageGsp: 6660293, maxGsp: 6901858 },
  { timestamp: 1585720883690, averageGsp: 6666999, maxGsp: 6908807 },
  { timestamp: 1585749693381, averageGsp: 6665546, maxGsp: 6907302 },
  { timestamp: 1585778503355, averageGsp: 6677408, maxGsp: 6919594 },
  { timestamp: 1585807313382, averageGsp: 6674648, maxGsp: 6916734 },
  { timestamp: 1585836123240, averageGsp: 6673924, maxGsp: 6915983 },
  { timestamp: 1585864933896, averageGsp: 6682815, maxGsp: 6925197 },
  { timestamp: 1585893744552, averageGsp: 6691837, maxGsp: 6934546 },
  { timestamp: 1585922554677, averageGsp: 6684470, maxGsp: 6926912 },
  { timestamp: 1585980374295, averageGsp: 6694603, maxGsp: 6937412 },
  { timestamp: 1586009185572, averageGsp: 6694158, maxGsp: 6936951 },
  { timestamp: 1586037995290, averageGsp: 6702821, maxGsp: 6945928 },
  { timestamp: 1586095769687, averageGsp: 6708154, maxGsp: 6951455 },
  { timestamp: 1586124579399, averageGsp: 6708350, maxGsp: 6951658 },
  { timestamp: 1586153389944, averageGsp: 6710796, maxGsp: 6954193 },
  { timestamp: 1586182200900, averageGsp: 6714669, maxGsp: 6958206 },
  { timestamp: 1586211011710, averageGsp: 6725549, maxGsp: 6969481 },
  { timestamp: 1586239822020, averageGsp: 6728413, maxGsp: 6972449 },
  { timestamp: 1586268631978, averageGsp: 6725911, maxGsp: 6969856 },
  { timestamp: 1586297441874, averageGsp: 6727977, maxGsp: 6971997 },
  { timestamp: 1586326252116, averageGsp: 6731624, maxGsp: 6975776 },
  { timestamp: 1586355061816, averageGsp: 6732577, maxGsp: 6976764 },
  { timestamp: 1586383871835, averageGsp: 6735640, maxGsp: 6979938 },
  { timestamp: 1586412683930, averageGsp: 6742988, maxGsp: 6987552 },
  { timestamp: 1586441494106, averageGsp: 6744432, maxGsp: 6989049 },
  { timestamp: 1586470314094, averageGsp: 6744476, maxGsp: 6989094 },
  { timestamp: 1586499125137, averageGsp: 6747229, maxGsp: 6991947 },
  { timestamp: 1586527935772, averageGsp: 6750822, maxGsp: 6995670 },
  { timestamp: 1586556745348, averageGsp: 6753534, maxGsp: 6998481 },
  { timestamp: 1586585555485, averageGsp: 6757509, maxGsp: 7002600 },
  { timestamp: 1586614365877, averageGsp: 6760752, maxGsp: 7005961 },
  { timestamp: 1586643175979, averageGsp: 6760359, maxGsp: 7005553 },
  { timestamp: 1586700951192, averageGsp: 6771847, maxGsp: 7017458 },
  { timestamp: 1586729760770, averageGsp: 6773967, maxGsp: 7019655 },
  { timestamp: 1586758570636, averageGsp: 6780446, maxGsp: 7026369 },
  { timestamp: 1586787380353, averageGsp: 6784676, maxGsp: 7030752 },
  { timestamp: 1586816191349, averageGsp: 6790606, maxGsp: 7036897 },
  { timestamp: 1586873933294, averageGsp: 6794997, maxGsp: 7041448 },
  { timestamp: 1586902743642, averageGsp: 6797974, maxGsp: 7044533 },
  { timestamp: 1586931554029, averageGsp: 6802419, maxGsp: 7049139 },
  { timestamp: 1586960363885, averageGsp: 6805448, maxGsp: 7052278 },
  { timestamp: 1586989173863, averageGsp: 6812080, maxGsp: 7059150 },
  { timestamp: 1587017984018, averageGsp: 6811576, maxGsp: 7058628 },
  { timestamp: 1587046794074, averageGsp: 6812699, maxGsp: 7059792 },
  { timestamp: 1587075604220, averageGsp: 6808942, maxGsp: 7055898 },
  { timestamp: 1587104417751, averageGsp: 6811224, maxGsp: 7058263 },
  { timestamp: 1587133227590, averageGsp: 6816098, maxGsp: 7063314 },
  { timestamp: 1587162038325, averageGsp: 6821335, maxGsp: 7068741 },
  { timestamp: 1587190848478, averageGsp: 6822560, maxGsp: 7070010 },
  { timestamp: 1587219658223, averageGsp: 6817334, maxGsp: 7064595 },
  { timestamp: 1587248467776, averageGsp: 6843763, maxGsp: 7091982 },
  { timestamp: 1587277277721, averageGsp: 6833563, maxGsp: 7081412 },
  { timestamp: 1587306087402, averageGsp: 6836254, maxGsp: 7084201 },
  { timestamp: 1587334897407, averageGsp: 6840717, maxGsp: 7088826 },
  { timestamp: 1587363708375, averageGsp: 6837925, maxGsp: 7085933 },
  { timestamp: 1587392518379, averageGsp: 6856885, maxGsp: 7105580 },
  { timestamp: 1587421329991, averageGsp: 6850202, maxGsp: 7098655 },
  { timestamp: 1587450140034, averageGsp: 6849543, maxGsp: 7097972 },
  { timestamp: 1587478951151, averageGsp: 6858002, maxGsp: 7106738 },
  { timestamp: 1587507761571, averageGsp: 6853205, maxGsp: 7101767 },
  { timestamp: 1587536572045, averageGsp: 6857995, maxGsp: 7106731 },
  { timestamp: 1587565382017, averageGsp: 6863890, maxGsp: 7112839 },
  { timestamp: 1587594193027, averageGsp: 6868503, maxGsp: 7117620 },
  { timestamp: 1587623007498, averageGsp: 6867886, maxGsp: 7116980 },
  { timestamp: 1587651819147, averageGsp: 6869887, maxGsp: 7119054 },
  { timestamp: 1587680648107, averageGsp: 6869972, maxGsp: 7119142 },
  { timestamp: 1587709458092, averageGsp: 6870238, maxGsp: 7119418 },
  { timestamp: 1587738268811, averageGsp: 6872316, maxGsp: 7121571 },
  { timestamp: 1587767079511, averageGsp: 6883844, maxGsp: 7133517 },
  { timestamp: 1587795890051, averageGsp: 6886723, maxGsp: 7136501 },
  { timestamp: 1587824700563, averageGsp: 6885657, maxGsp: 7135396 },
  { timestamp: 1587853510873, averageGsp: 6887066, maxGsp: 7136856 },
  { timestamp: 1587882320869, averageGsp: 6895456, maxGsp: 7145550 },
  { timestamp: 1587911131353, averageGsp: 6896373, maxGsp: 7146501 },
  { timestamp: 1587939942577, averageGsp: 6895865, maxGsp: 7145974 },
  { timestamp: 1587968752403, averageGsp: 6900312, maxGsp: 7150582 },
  { timestamp: 1587997565259, averageGsp: 6898234, maxGsp: 7148429 },
  { timestamp: 1588026376610, averageGsp: 6909282, maxGsp: 7159878 },
  { timestamp: 1588055186346, averageGsp: 6906885, maxGsp: 7157394 },
  { timestamp: 1588083996450, averageGsp: 6913512, maxGsp: 7164261 },
  { timestamp: 1588112807477, averageGsp: 6924465, maxGsp: 7175611 },
  { timestamp: 1588141618150, averageGsp: 6917851, maxGsp: 7168758 },
  { timestamp: 1588170428952, averageGsp: 6915935, maxGsp: 7166772 },
  { timestamp: 1588199239538, averageGsp: 6920308, maxGsp: 7171304 },
  { timestamp: 1588257030814, averageGsp: 6925742, maxGsp: 7176935 },
  { timestamp: 1588343801669, averageGsp: 6940694, maxGsp: 7192429 },
  { timestamp: 1588372612832, averageGsp: 6944907, maxGsp: 7196795 },
  { timestamp: 1588401423375, averageGsp: 6949950, maxGsp: 7202021 },
  { timestamp: 1588430233888, averageGsp: 6946944, maxGsp: 7198906 },
  { timestamp: 1588459044244, averageGsp: 6945429, maxGsp: 7197336 },
  { timestamp: 1588487854449, averageGsp: 6953078, maxGsp: 7205262 },
  { timestamp: 1588516665181, averageGsp: 6960510, maxGsp: 7212964 },
  { timestamp: 1588545476606, averageGsp: 6965184, maxGsp: 7217807 },
  { timestamp: 1588574286988, averageGsp: 6969128, maxGsp: 7221894 },
  { timestamp: 1588603098667, averageGsp: 6970891, maxGsp: 7223721 },
  { timestamp: 1588631910049, averageGsp: 6968246, maxGsp: 7220980 },
  { timestamp: 1588660719895, averageGsp: 6968620, maxGsp: 7221368 },
  { timestamp: 1588689530105, averageGsp: 6973416, maxGsp: 7226338 },
  { timestamp: 1588718340410, averageGsp: 6980068, maxGsp: 7233231 },
  { timestamp: 1588747150614, averageGsp: 6978661, maxGsp: 7231773 },
  { timestamp: 1588775961971, averageGsp: 6984722, maxGsp: 7238054 },
  { timestamp: 1588804773071, averageGsp: 6989964, maxGsp: 7243486 },
  { timestamp: 1588833582875, averageGsp: 6988266, maxGsp: 7241726 },
  { timestamp: 1588862393382, averageGsp: 6995837, maxGsp: 7249572 },
  { timestamp: 1588891203389, averageGsp: 6993604, maxGsp: 7247258 },
  { timestamp: 1588920014217, averageGsp: 6999487, maxGsp: 7253354 },
  { timestamp: 1588948824779, averageGsp: 7003367, maxGsp: 7257375 },
  { timestamp: 1588977634631, averageGsp: 7007780, maxGsp: 7261948 },
  { timestamp: 1589006444788, averageGsp: 7006876, maxGsp: 7261011 },
  { timestamp: 1589035256957, averageGsp: 7006225, maxGsp: 7260337 },
  { timestamp: 1589064070070, averageGsp: 7004583, maxGsp: 7258635 },
  { timestamp: 1589092881653, averageGsp: 7007914, maxGsp: 7262087 },
  { timestamp: 1589121692969, averageGsp: 7016753, maxGsp: 7271247 },
  { timestamp: 1589150506261, averageGsp: 7011241, maxGsp: 7265535 },
  { timestamp: 1589179315878, averageGsp: 7019963, maxGsp: 7274573 },
  { timestamp: 1589208127471, averageGsp: 7028387, maxGsp: 7283303 },
  { timestamp: 1589236938985, averageGsp: 7024238, maxGsp: 7279003 },
  { timestamp: 1589265749504, averageGsp: 7025607, maxGsp: 7280422 },
  { timestamp: 1589294560371, averageGsp: 7029364, maxGsp: 7284315 },
  { timestamp: 1589323371051, averageGsp: 7037976, maxGsp: 7293239 },
  { timestamp: 1589352181392, averageGsp: 7033406, maxGsp: 7288504 },
  { timestamp: 1589380994226, averageGsp: 7036580, maxGsp: 7291793 },
  { timestamp: 1589409807576, averageGsp: 7044354, maxGsp: 7299849 },
  { timestamp: 1589438621462, averageGsp: 7045758, maxGsp: 7301304 },
  { timestamp: 1589467433342, averageGsp: 7039733, maxGsp: 7295060 },
  { timestamp: 1589496243541, averageGsp: 7041441, maxGsp: 7296830 },
  { timestamp: 1589525054388, averageGsp: 7045005, maxGsp: 7300523 },
  { timestamp: 1589553864533, averageGsp: 7047999, maxGsp: 7303626 },
  { timestamp: 1589582677372, averageGsp: 7052259, maxGsp: 7308040 },
  { timestamp: 1589611491891, averageGsp: 7050252, maxGsp: 7305961 },
  { timestamp: 1589640303605, averageGsp: 7055351, maxGsp: 7311245 },
  { timestamp: 1589669116606, averageGsp: 7062277, maxGsp: 7318422 },
  { timestamp: 1589697926960, averageGsp: 7065274, maxGsp: 7321527 },
  { timestamp: 1589726738728, averageGsp: 7065881, maxGsp: 7322156 },
  { timestamp: 1589755548813, averageGsp: 7065342, maxGsp: 7321598 },
  { timestamp: 1589784358922, averageGsp: 7066183, maxGsp: 7322469 },
  { timestamp: 1589813169586, averageGsp: 7067365, maxGsp: 7323694 },
  { timestamp: 1589841981171, averageGsp: 7070916, maxGsp: 7327374 },
  { timestamp: 1589870791767, averageGsp: 7072502, maxGsp: 7329018 },
  { timestamp: 1589899602160, averageGsp: 7076993, maxGsp: 7333672 },
  { timestamp: 1589928413250, averageGsp: 7083717, maxGsp: 7340639 },
  { timestamp: 1589957223692, averageGsp: 7084245, maxGsp: 7341187 },
  { timestamp: 1589986034598, averageGsp: 7081706, maxGsp: 7338555 },
  { timestamp: 1590014845345, averageGsp: 7078144, maxGsp: 7334864 },
  { timestamp: 1590043655724, averageGsp: 7082433, maxGsp: 7339309 },
  { timestamp: 1590072466437, averageGsp: 7082658, maxGsp: 7339542 },
  { timestamp: 1590101276882, averageGsp: 7084784, maxGsp: 7341745 },
  { timestamp: 1590130087251, averageGsp: 7093584, maxGsp: 7350864 },
  { timestamp: 1590158897988, averageGsp: 7101060, maxGsp: 7358611 },
  { timestamp: 1590187708416, averageGsp: 7097107, maxGsp: 7354515 },
  { timestamp: 1590216518826, averageGsp: 7096758, maxGsp: 7354153 },
  { timestamp: 1590245330758, averageGsp: 7105870, maxGsp: 7363596 },
  { timestamp: 1590274141283, averageGsp: 7106763, maxGsp: 7364521 },
  { timestamp: 1590302951295, averageGsp: 7114559, maxGsp: 7372600 },
  { timestamp: 1590331761751, averageGsp: 7113149, maxGsp: 7371139 },
  { timestamp: 1590360572585, averageGsp: 7118997, maxGsp: 7377199 },
  { timestamp: 1590389382818, averageGsp: 7120700, maxGsp: 7378964 },
  { timestamp: 1590418193349, averageGsp: 7116125, maxGsp: 7374223 },
  { timestamp: 1590447008956, averageGsp: 7117022, maxGsp: 7375152 },
  { timestamp: 1590475820102, averageGsp: 7129317, maxGsp: 7387893 },
  { timestamp: 1590504631044, averageGsp: 7129414, maxGsp: 7387994 },
  { timestamp: 1590533441248, averageGsp: 7126759, maxGsp: 7385242 },
  { timestamp: 1590562251889, averageGsp: 7126688, maxGsp: 7385169 },
  { timestamp: 1590591063017, averageGsp: 7129595, maxGsp: 7388181 },
  { timestamp: 1590619873525, averageGsp: 7125947, maxGsp: 7384401 },
  { timestamp: 1590648683959, averageGsp: 7131753, maxGsp: 7390418 },
  { timestamp: 1590677495056, averageGsp: 7134818, maxGsp: 7393594 },
  { timestamp: 1590706305714, averageGsp: 7140179, maxGsp: 7399149 },
  { timestamp: 1590735116509, averageGsp: 7141519, maxGsp: 7400538 },
  { timestamp: 1590763928455, averageGsp: 7144313, maxGsp: 7403433 },
  { timestamp: 1590792738965, averageGsp: 7147644, maxGsp: 7406885 },
  { timestamp: 1590821549578, averageGsp: 7151252, maxGsp: 7410624 },
  { timestamp: 1590850361060, averageGsp: 7150810, maxGsp: 7410166 },
  { timestamp: 1590879171545, averageGsp: 7154506, maxGsp: 7413996 },
  { timestamp: 1590907987984, averageGsp: 7152347, maxGsp: 7411759 },
  { timestamp: 1590936799168, averageGsp: 7155509, maxGsp: 7415035 },
  { timestamp: 1590965609681, averageGsp: 7157455, maxGsp: 7417052 },
  { timestamp: 1590994420106, averageGsp: 7162406, maxGsp: 7422182 },
  { timestamp: 1591023231197, averageGsp: 7160163, maxGsp: 7419858 },
  { timestamp: 1591052041689, averageGsp: 7161607, maxGsp: 7421354 },
  { timestamp: 1591080854158, averageGsp: 7166468, maxGsp: 7426392 },
  { timestamp: 1591109665003, averageGsp: 7175101, maxGsp: 7435338 },
  { timestamp: 1591138475386, averageGsp: 7172987, maxGsp: 7433147 },
  { timestamp: 1591167286910, averageGsp: 7175442, maxGsp: 7435691 },
  { timestamp: 1591196101552, averageGsp: 7175861, maxGsp: 7436125 },
  { timestamp: 1591224912825, averageGsp: 7177090, maxGsp: 7437399 },
  { timestamp: 1591253723388, averageGsp: 7178489, maxGsp: 7438849 },
  { timestamp: 1591282533762, averageGsp: 7180772, maxGsp: 7441215 },
  { timestamp: 1591311344531, averageGsp: 7181724, maxGsp: 7442201 },
  { timestamp: 1591340155170, averageGsp: 7187423, maxGsp: 7448107 },
  { timestamp: 1591368967322, averageGsp: 7191421, maxGsp: 7452250 },
  { timestamp: 1591397778146, averageGsp: 7190482, maxGsp: 7451277 },
  { timestamp: 1591426588516, averageGsp: 7194014, maxGsp: 7454937 },
  { timestamp: 1591455399479, averageGsp: 7195493, maxGsp: 7456469 },
  { timestamp: 1591484209807, averageGsp: 7204137, maxGsp: 7465427 },
  { timestamp: 1591513020282, averageGsp: 7202302, maxGsp: 7463525 },
  { timestamp: 1591541831996, averageGsp: 7201605, maxGsp: 7462803 },
  { timestamp: 1591570642936, averageGsp: 7205590, maxGsp: 7466933 },
  { timestamp: 1591599453552, averageGsp: 7204317, maxGsp: 7465613 },
  { timestamp: 1591628264518, averageGsp: 7209109, maxGsp: 7470579 },
  { timestamp: 1591657075370, averageGsp: 7211302, maxGsp: 7472852 },
  { timestamp: 1591685886573, averageGsp: 7214294, maxGsp: 7475952 },
  { timestamp: 1591714700522, averageGsp: 7219086, maxGsp: 7480918 },
  { timestamp: 1591743511197, averageGsp: 7223811, maxGsp: 7485815 },
  { timestamp: 1591772322024, averageGsp: 7223850, maxGsp: 7485855 },
  { timestamp: 1591801133290, averageGsp: 7222247, maxGsp: 7484194 },
  { timestamp: 1591829943964, averageGsp: 7233348, maxGsp: 7495697 },
  { timestamp: 1591858754744, averageGsp: 7232314, maxGsp: 7494626 },
  { timestamp: 1591887565741, averageGsp: 7227789, maxGsp: 7489937 },
  { timestamp: 1591916376620, averageGsp: 7230544, maxGsp: 7492792 },
  { timestamp: 1591945187117, averageGsp: 7229288, maxGsp: 7491490 },
  { timestamp: 1591973998325, averageGsp: 7239975, maxGsp: 7502565 },
  { timestamp: 1592002811762, averageGsp: 7235856, maxGsp: 7498296 },
  { timestamp: 1592031622648, averageGsp: 7240440, maxGsp: 7503047 },
  { timestamp: 1592060433983, averageGsp: 7243245, maxGsp: 7505953 },
  { timestamp: 1592089244744, averageGsp: 7239349, maxGsp: 7501916 },
  { timestamp: 1592118055419, averageGsp: 7251527, maxGsp: 7514536 },
  { timestamp: 1592146866454, averageGsp: 7248509, maxGsp: 7511408 },
  { timestamp: 1592175676917, averageGsp: 7252915, maxGsp: 7515974 },
  { timestamp: 1592204487362, averageGsp: 7261883, maxGsp: 7525267 },
  { timestamp: 1592233298616, averageGsp: 7263127, maxGsp: 7526556 },
  { timestamp: 1592262109893, averageGsp: 7256512, maxGsp: 7519702 },
  { timestamp: 1592319911871, averageGsp: 7261452, maxGsp: 7524821 },
  { timestamp: 1592348723302, averageGsp: 7262585, maxGsp: 7525995 },
  { timestamp: 1592377535406, averageGsp: 7265159, maxGsp: 7528662 },
  { timestamp: 1592406346840, averageGsp: 7266818, maxGsp: 7530381 },
  { timestamp: 1592435157301, averageGsp: 7275840, maxGsp: 7539731 },
  { timestamp: 1592463968552, averageGsp: 7275593, maxGsp: 7539475 },
  { timestamp: 1592492779694, averageGsp: 7268926, maxGsp: 7532566 },
  { timestamp: 1592521591093, averageGsp: 7273439, maxGsp: 7537242 },
  { timestamp: 1592550401951, averageGsp: 7275716, maxGsp: 7539602 },
  { timestamp: 1592579213264, averageGsp: 7274330, maxGsp: 7538166 },
  { timestamp: 1592608023800, averageGsp: 7277876, maxGsp: 7541840 },
  { timestamp: 1592636835510, averageGsp: 7276897, maxGsp: 7540826 },
  { timestamp: 1592665646721, averageGsp: 7282867, maxGsp: 7547012 },
  { timestamp: 1592694457298, averageGsp: 7281409, maxGsp: 7545502 },
  { timestamp: 1592723268132, averageGsp: 7284021, maxGsp: 7548208 },
  { timestamp: 1592752079305, averageGsp: 7287981, maxGsp: 7552312 },
  { timestamp: 1592780892254, averageGsp: 7292467, maxGsp: 7556961 },
  { timestamp: 1592809702983, averageGsp: 7292271, maxGsp: 7556758 },
  { timestamp: 1592838514126, averageGsp: 7299980, maxGsp: 7564746 },
  { timestamp: 1592867326126, averageGsp: 7297932, maxGsp: 7562624 },
  { timestamp: 1592896137167, averageGsp: 7297948, maxGsp: 7562640 },
  { timestamp: 1592924949940, averageGsp: 7301075, maxGsp: 7565881 },
  { timestamp: 1592953760959, averageGsp: 7319941, maxGsp: 7585431 },
  { timestamp: 1592982573477, averageGsp: 7311986, maxGsp: 7577188 },
  { timestamp: 1593011385257, averageGsp: 7308592, maxGsp: 7573670 },
  { timestamp: 1593040196706, averageGsp: 7306092, maxGsp: 7571080 },
  { timestamp: 1593069008718, averageGsp: 7310435, maxGsp: 7575580 },
  { timestamp: 1593097819974, averageGsp: 7308512, maxGsp: 7573588 },
  { timestamp: 1593126631036, averageGsp: 7307064, maxGsp: 7572087 },
  { timestamp: 1593155442418, averageGsp: 7315209, maxGsp: 7580527 },
  { timestamp: 1593184253787, averageGsp: 7322895, maxGsp: 7588492 },
  { timestamp: 1593213065558, averageGsp: 7329800, maxGsp: 7595648 },
  { timestamp: 1593241876764, averageGsp: 7323482, maxGsp: 7589101 },
  { timestamp: 1593270687975, averageGsp: 7323522, maxGsp: 7589142 },
  { timestamp: 1593299499309, averageGsp: 7330954, maxGsp: 7596844 },
  { timestamp: 1593328310939, averageGsp: 7331548, maxGsp: 7597459 },
  { timestamp: 1593357122686, averageGsp: 7331060, maxGsp: 7596953 },
  { timestamp: 1593385933271, averageGsp: 7329999, maxGsp: 7595854 },
  { timestamp: 1593414744117, averageGsp: 7334728, maxGsp: 7600754 },
  { timestamp: 1593443555767, averageGsp: 7335705, maxGsp: 7601767 },
  { timestamp: 1593501356978, averageGsp: 7346671, maxGsp: 7613131 },
  { timestamp: 1593530168837, averageGsp: 7347729, maxGsp: 7614227 },
  { timestamp: 1593558979670, averageGsp: 7350766, maxGsp: 7617374 },
  { timestamp: 1593587791627, averageGsp: 7350543, maxGsp: 7617143 },
  { timestamp: 1593616603111, averageGsp: 7347078, maxGsp: 7613552 },
  { timestamp: 1593645414246, averageGsp: 7355839, maxGsp: 7622631 },
  { timestamp: 1593674226414, averageGsp: 7355937, maxGsp: 7622733 },
  { timestamp: 1593703037623, averageGsp: 7356879, maxGsp: 7623709 },
  { timestamp: 1593760763258, averageGsp: 7367099, maxGsp: 7634299 },
  { timestamp: 1593789574233, averageGsp: 7362166, maxGsp: 7629188 },
  { timestamp: 1593847403491, averageGsp: 7361723, maxGsp: 7628728 },
  { timestamp: 1593876214456, averageGsp: 7370087, maxGsp: 7637396 },
  { timestamp: 1593905025100, averageGsp: 7374292, maxGsp: 7641753 },
  { timestamp: 1593933835925, averageGsp: 7374084, maxGsp: 7641538 },
  { timestamp: 1593962648325, averageGsp: 7375712, maxGsp: 7643225 },
  { timestamp: 1593991459093, averageGsp: 7370422, maxGsp: 7637743 },
  { timestamp: 1594020269981, averageGsp: 7383146, maxGsp: 7650928 },
  { timestamp: 1594049083410, averageGsp: 7386039, maxGsp: 7653926 },
  { timestamp: 1594077895398, averageGsp: 7385957, maxGsp: 7653841 },
  { timestamp: 1594106707460, averageGsp: 7389563, maxGsp: 7657578 },
  { timestamp: 1594135518854, averageGsp: 7391178, maxGsp: 7659252 },
  { timestamp: 1594164330420, averageGsp: 7387658, maxGsp: 7655604 },
  { timestamp: 1594193142074, averageGsp: 7394075, maxGsp: 7662254 },
  { timestamp: 1594221953604, averageGsp: 7406516, maxGsp: 7675146 },
  { timestamp: 1594250764572, averageGsp: 7406613, maxGsp: 7675247 },
  { timestamp: 1594279575535, averageGsp: 7406981, maxGsp: 7675628 },
  { timestamp: 1594308387092, averageGsp: 7406305, maxGsp: 7674927 },
  { timestamp: 1594366223817, averageGsp: 7408326, maxGsp: 7677022 },
  { timestamp: 1594395035400, averageGsp: 7409650, maxGsp: 7678394 },
  { timestamp: 1594423846457, averageGsp: 7407940, maxGsp: 7676622 },
  { timestamp: 1594452657647, averageGsp: 7413626, maxGsp: 7682514 },
  { timestamp: 1594481468764, averageGsp: 7415975, maxGsp: 7684948 },
  { timestamp: 1594539264394, averageGsp: 7419999, maxGsp: 7689118 },
  { timestamp: 1594568075331, averageGsp: 7421306, maxGsp: 7690473 },
  { timestamp: 1594596886332, averageGsp: 7426198, maxGsp: 7695542 },
  { timestamp: 1594625696812, averageGsp: 7428924, maxGsp: 7698367 },
  { timestamp: 1594654509494, averageGsp: 7432895, maxGsp: 7702482 },
  { timestamp: 1594683320515, averageGsp: 7430301, maxGsp: 7699794 },
  { timestamp: 1594712131800, averageGsp: 7430010, maxGsp: 7699492 },
  { timestamp: 1594740943077, averageGsp: 7433347, maxGsp: 7702950 },
  { timestamp: 1594769754554, averageGsp: 7443290, maxGsp: 7713254 },
  { timestamp: 1594798565952, averageGsp: 7441743, maxGsp: 7711651 },
  { timestamp: 1594827377441, averageGsp: 7438101, maxGsp: 7707877 },
  { timestamp: 1594856188831, averageGsp: 7448663, maxGsp: 7718822 },
  { timestamp: 1594885000357, averageGsp: 7448419, maxGsp: 7718569 },
  { timestamp: 1594913812095, averageGsp: 7445001, maxGsp: 7715027 },
  { timestamp: 1594971543812, averageGsp: 7450276, maxGsp: 7720493 },
  { timestamp: 1595000355182, averageGsp: 7454596, maxGsp: 7724970 },
  { timestamp: 1595029166597, averageGsp: 7449317, maxGsp: 7719499 },
  { timestamp: 1595057977968, averageGsp: 7449727, maxGsp: 7719924 },
  { timestamp: 1595086789694, averageGsp: 7449185, maxGsp: 7719363 },
  { timestamp: 1595115600255, averageGsp: 7452312, maxGsp: 7722603 },
  { timestamp: 1595144411489, averageGsp: 7459065, maxGsp: 7729601 },
  { timestamp: 1595173222528, averageGsp: 7464244, maxGsp: 7734968 },
  { timestamp: 1595202033451, averageGsp: 7463374, maxGsp: 7734066 },
  { timestamp: 1595230844431, averageGsp: 7463713, maxGsp: 7734418 },
  { timestamp: 1595259655681, averageGsp: 7465661, maxGsp: 7736436 },
  { timestamp: 1595288467530, averageGsp: 7470026, maxGsp: 7740960 },
  { timestamp: 1595317278434, averageGsp: 7470736, maxGsp: 7741695 },
  { timestamp: 1595346090216, averageGsp: 7469379, maxGsp: 7740289 },
  { timestamp: 1595374902193, averageGsp: 7468180, maxGsp: 7739047 },
  { timestamp: 1595403713249, averageGsp: 7470232, maxGsp: 7741173 },
  { timestamp: 1595432525092, averageGsp: 7474435, maxGsp: 7745528 },
  { timestamp: 1595461336325, averageGsp: 7480266, maxGsp: 7751571 },
  { timestamp: 1595490147069, averageGsp: 7483312, maxGsp: 7754727 },
  { timestamp: 1595518958119, averageGsp: 7489771, maxGsp: 7761421 },
  { timestamp: 1595547768887, averageGsp: 7488068, maxGsp: 7759656 },
  { timestamp: 1595576580414, averageGsp: 7488254, maxGsp: 7759849 },
  { timestamp: 1595605392498, averageGsp: 7488780, maxGsp: 7760394 },
  { timestamp: 1595634203704, averageGsp: 7500951, maxGsp: 7773006 },
  { timestamp: 1595663014888, averageGsp: 7495488, maxGsp: 7767345 },
  { timestamp: 1595691826695, averageGsp: 7497796, maxGsp: 7769737 },
  { timestamp: 1595720638613, averageGsp: 7503830, maxGsp: 7775990 },
  { timestamp: 1595749450200, averageGsp: 7508981, maxGsp: 7781327 },
  { timestamp: 1595778261536, averageGsp: 7514246, maxGsp: 7786783 },
  { timestamp: 1595807072786, averageGsp: 7507307, maxGsp: 7779593 },
  { timestamp: 1595835883685, averageGsp: 7505602, maxGsp: 7777826 },
  { timestamp: 1595864696518, averageGsp: 7507213, maxGsp: 7779495 },
  { timestamp: 1595893508659, averageGsp: 7513153, maxGsp: 7785651 },
  { timestamp: 1595922319704, averageGsp: 7517055, maxGsp: 7789694 },
  { timestamp: 1595951131560, averageGsp: 7516315, maxGsp: 7788927 },
  { timestamp: 1595979942461, averageGsp: 7521367, maxGsp: 7794163 },
  { timestamp: 1596008753569, averageGsp: 7520426, maxGsp: 7793188 },
  { timestamp: 1596037569503, averageGsp: 7522734, maxGsp: 7795579 },
  { timestamp: 1596066382723, averageGsp: 7523782, maxGsp: 7796665 },
  { timestamp: 1596095193973, averageGsp: 7529261, maxGsp: 7802343 },
  { timestamp: 1596124005414, averageGsp: 7529093, maxGsp: 7802169 },
  { timestamp: 1596152816346, averageGsp: 7524586, maxGsp: 7797498 },
  { timestamp: 1596181627900, averageGsp: 7527111, maxGsp: 7800115 },
  { timestamp: 1596210439743, averageGsp: 7531086, maxGsp: 7804234 },
  { timestamp: 1596239251166, averageGsp: 7533026, maxGsp: 7806245 },
  { timestamp: 1596268062406, averageGsp: 7540876, maxGsp: 7814379 },
  { timestamp: 1596296873482, averageGsp: 7537265, maxGsp: 7810637 },
  { timestamp: 1596325685685, averageGsp: 7535514, maxGsp: 7808823 },
  { timestamp: 1596354497021, averageGsp: 7539724, maxGsp: 7813185 },
  { timestamp: 1596383308224, averageGsp: 7541636, maxGsp: 7815167 },
  { timestamp: 1596412118818, averageGsp: 7546499, maxGsp: 7820206 },
  { timestamp: 1596440929560, averageGsp: 7546592, maxGsp: 7820303 },
  { timestamp: 1596469741118, averageGsp: 7550509, maxGsp: 7824362 },
  { timestamp: 1596498552029, averageGsp: 7556912, maxGsp: 7830997 },
  { timestamp: 1596527363189, averageGsp: 7557733, maxGsp: 7831848 },
  { timestamp: 1596556175211, averageGsp: 7562398, maxGsp: 7836682 },
  { timestamp: 1596584987275, averageGsp: 7558973, maxGsp: 7833133 },
  { timestamp: 1596613798991, averageGsp: 7383558, maxGsp: 7651355 },
  { timestamp: 1596642612692, averageGsp: 7382312, maxGsp: 7770855 },
  { timestamp: 1596671423842, averageGsp: 7380621, maxGsp: 7769075 },
  { timestamp: 1596700235573, averageGsp: 7377711, maxGsp: 7766012 },
  { timestamp: 1596729047277, averageGsp: 7375297, maxGsp: 7763471 },
  { timestamp: 1596757859322, averageGsp: 7379692, maxGsp: 7768097 },
  { timestamp: 1596786671464, averageGsp: 7376731, maxGsp: 7764980 },
  { timestamp: 1596815483103, averageGsp: 7375953, maxGsp: 7764161 },
  { timestamp: 1596873247038, averageGsp: 7379952, maxGsp: 7768371 },
  { timestamp: 1596902058708, averageGsp: 7385146, maxGsp: 7773838 },
  { timestamp: 1596959869563, averageGsp: 7378485, maxGsp: 7766826 },
  { timestamp: 1596988681696, averageGsp: 7387645, maxGsp: 7776468 },
  { timestamp: 1597017493164, averageGsp: 7386112, maxGsp: 7774855 },
  { timestamp: 1597046304881, averageGsp: 7386296, maxGsp: 7775048 },
  { timestamp: 1597075118938, averageGsp: 7398887, maxGsp: 7788302 },
  { timestamp: 1597103931379, averageGsp: 7394161, maxGsp: 7783327 },
  { timestamp: 1597132742967, averageGsp: 7394037, maxGsp: 7783197 },
  { timestamp: 1597161555201, averageGsp: 7392580, maxGsp: 7781663 },
  { timestamp: 1597219329185, averageGsp: 7398671, maxGsp: 7788075 },
  { timestamp: 1597248141362, averageGsp: 7399411, maxGsp: 7788854 },
  { timestamp: 1597276953061, averageGsp: 7404855, maxGsp: 7794584 },
  { timestamp: 1597305764626, averageGsp: 7405211, maxGsp: 7794959 },
  { timestamp: 1597334576897, averageGsp: 7405017, maxGsp: 7794755 },
  { timestamp: 1597363388751, averageGsp: 7406986, maxGsp: 7796827 },
  { timestamp: 1597392200199, averageGsp: 7407598, maxGsp: 7797472 },
  { timestamp: 1597421012659, averageGsp: 7408678, maxGsp: 7798608 },
  { timestamp: 1597449824295, averageGsp: 7409179, maxGsp: 7799136 },
  { timestamp: 1597478635957, averageGsp: 7413222, maxGsp: 7803392 },
  { timestamp: 1597507448507, averageGsp: 7421581, maxGsp: 7812191 },
  { timestamp: 1597536261111, averageGsp: 7418905, maxGsp: 7809374 },
  { timestamp: 1597565072415, averageGsp: 7421682, maxGsp: 7812297 },
  { timestamp: 1597593884568, averageGsp: 7421659, maxGsp: 7812273 },
  { timestamp: 1597622696229, averageGsp: 7428094, maxGsp: 7819046 },
  { timestamp: 1597651510095, averageGsp: 7429321, maxGsp: 7820338 },
  { timestamp: 1597680322619, averageGsp: 7429282, maxGsp: 7820297 },
  { timestamp: 1597709134661, averageGsp: 7434163, maxGsp: 7825435 },
  { timestamp: 1597737946407, averageGsp: 7438944, maxGsp: 7830467 },
  { timestamp: 1597766759234, averageGsp: 7431557, maxGsp: 7822692 },
  { timestamp: 1597795572248, averageGsp: 7439987, maxGsp: 7831565 },
  { timestamp: 1597824384384, averageGsp: 7441857, maxGsp: 7833534 },
  { timestamp: 1597853198144, averageGsp: 7433511, maxGsp: 7824748 },
  { timestamp: 1597882011438, averageGsp: 7440669, maxGsp: 7832283 },
  { timestamp: 1597910823502, averageGsp: 7444184, maxGsp: 7835983 },
  { timestamp: 1597939636235, averageGsp: 7444388, maxGsp: 7836198 },
  { timestamp: 1597968449432, averageGsp: 7443004, maxGsp: 7834741 },
  { timestamp: 1597997274840, averageGsp: 7442662, maxGsp: 7834381 },
  { timestamp: 1598026089328, averageGsp: 7442527, maxGsp: 7834239 },
  { timestamp: 1598054901741, averageGsp: 7444096, maxGsp: 7835891 },
  { timestamp: 1598083714771, averageGsp: 7448786, maxGsp: 7840827 },
  { timestamp: 1598112527631, averageGsp: 7447941, maxGsp: 7839938 },
  { timestamp: 1598141340094, averageGsp: 7455306, maxGsp: 7847691 },
  { timestamp: 1598170160107, averageGsp: 7458046, maxGsp: 7850575 },
  { timestamp: 1598198972216, averageGsp: 7462101, maxGsp: 7854843 },
  { timestamp: 1598227783386, averageGsp: 7461420, maxGsp: 7854126 },
  { timestamp: 1598256594229, averageGsp: 7462636, maxGsp: 7855406 },
  { timestamp: 1598285408448, averageGsp: 7467378, maxGsp: 7860398 },
  { timestamp: 1598314220531, averageGsp: 7461948, maxGsp: 7854682 },
  { timestamp: 1598343031641, averageGsp: 7464961, maxGsp: 7857854 },
  { timestamp: 1598371843704, averageGsp: 7470968, maxGsp: 7864177 },
  { timestamp: 1598400654519, averageGsp: 7470794, maxGsp: 7863994 },
  { timestamp: 1598429466680, averageGsp: 7473927, maxGsp: 7867292 },
  { timestamp: 1598458278667, averageGsp: 7469213, maxGsp: 7862329 },
  { timestamp: 1598487091014, averageGsp: 7471728, maxGsp: 7864977 },
  { timestamp: 1598515903536, averageGsp: 7477533, maxGsp: 7871087 },
  { timestamp: 1598544715716, averageGsp: 7478270, maxGsp: 7871863 },
  { timestamp: 1598573528293, averageGsp: 7474292, maxGsp: 7867676 },
  { timestamp: 1598602339552, averageGsp: 7475905, maxGsp: 7869374 },
  { timestamp: 1598631154221, averageGsp: 7475861, maxGsp: 7869327 },
  { timestamp: 1598659965338, averageGsp: 7477377, maxGsp: 7954656 },
  { timestamp: 1598688776480, averageGsp: 7477328, maxGsp: 7954604 },
  { timestamp: 1598717588219, averageGsp: 7482478, maxGsp: 7960083 },
  { timestamp: 1598746399497, averageGsp: 7484851, maxGsp: 7962607 },
  { timestamp: 1598775210538, averageGsp: 7483348, maxGsp: 7961009 },
  { timestamp: 1598804022946, averageGsp: 7485180, maxGsp: 7962957 },
  { timestamp: 1598832835627, averageGsp: 7490119, maxGsp: 7968212 },
  { timestamp: 1598861647589, averageGsp: 7494585, maxGsp: 7972963 },
  { timestamp: 1598890459225, averageGsp: 7492003, maxGsp: 7970216 },
  { timestamp: 1598919271903, averageGsp: 7493353, maxGsp: 7971652 },
  { timestamp: 1598948084119, averageGsp: 7499776, maxGsp: 7978485 },
  { timestamp: 1598976896016, averageGsp: 7495595, maxGsp: 7974037 },
  { timestamp: 1599005708289, averageGsp: 7505304, maxGsp: 7984366 },
  { timestamp: 1599034519494, averageGsp: 7505728, maxGsp: 7984817 },
  { timestamp: 1599063331358, averageGsp: 7503401, maxGsp: 7982341 },
  { timestamp: 1599092142724, averageGsp: 7499274, maxGsp: 7977951 },
  { timestamp: 1599120954573, averageGsp: 7503591, maxGsp: 7982544 },
  { timestamp: 1599149767220, averageGsp: 7500267, maxGsp: 7979007 },
  { timestamp: 1599178578473, averageGsp: 7503881, maxGsp: 7982852 },
  { timestamp: 1599207390135, averageGsp: 7511468, maxGsp: 7990923 },
  { timestamp: 1599236201910, averageGsp: 7508114, maxGsp: 7987355 },
  { timestamp: 1599265014015, averageGsp: 7511926, maxGsp: 7991411 },
  { timestamp: 1599293825944, averageGsp: 7513775, maxGsp: 7993378 },
  { timestamp: 1599322637700, averageGsp: 7514486, maxGsp: 7994134 },
  { timestamp: 1599351449242, averageGsp: 7511756, maxGsp: 7991230 },
  { timestamp: 1599380260531, averageGsp: 7514381, maxGsp: 7994022 },
  { timestamp: 1599409072990, averageGsp: 7517987, maxGsp: 7997859 },
  { timestamp: 1599437884919, averageGsp: 7521421, maxGsp: 8001512 },
  { timestamp: 1599466696352, averageGsp: 7518418, maxGsp: 7998317 },
  { timestamp: 1599495507808, averageGsp: 7517939, maxGsp: 7997807 },
  { timestamp: 1599524318786, averageGsp: 7526538, maxGsp: 8006955 },
  { timestamp: 1599553130235, averageGsp: 7528418, maxGsp: 8008955 },
  { timestamp: 1599581941700, averageGsp: 7527781, maxGsp: 8008278 },
  { timestamp: 1599610753804, averageGsp: 7531557, maxGsp: 8012295 },
  { timestamp: 1599639565174, averageGsp: 7536160, maxGsp: 8017191 },
  { timestamp: 1599668377168, averageGsp: 7542287, maxGsp: 8023710 },
  { timestamp: 1599697189086, averageGsp: 7531334, maxGsp: 8012057 },
  { timestamp: 1599726000217, averageGsp: 7535229, maxGsp: 8016201 },
  { timestamp: 1599754811851, averageGsp: 7531067, maxGsp: 8011773 },
  { timestamp: 1601926965741, averageGsp: 7628935, maxGsp: 8115888 },
  { timestamp: 1601926999119, averageGsp: 7628935, maxGsp: 8115888 },
  { timestamp: 1601927323323, averageGsp: 7628935, maxGsp: 8115888 },
  { timestamp: 1601927577849, averageGsp: 7628935, maxGsp: 8115888 },
  { timestamp: 1601927600710, averageGsp: 7628935, maxGsp: 8115888 },
  { timestamp: 1601927677915, averageGsp: 7628935, maxGsp: 8115888 },
  { timestamp: 1602014090614, averageGsp: 7636783, maxGsp: 8124237 },
  { timestamp: 1602100515441, averageGsp: 7638930, maxGsp: 8126521 },
  { timestamp: 1602186927395, averageGsp: 7650178, maxGsp: 8138487 },
  { timestamp: 1602273339767, averageGsp: 7655142, maxGsp: 8143768 },
  { timestamp: 1602359753249, averageGsp: 7666124, maxGsp: 8155451 },
  { timestamp: 1602446165486, averageGsp: 7670016, maxGsp: 8159591 },
  { timestamp: 1602532578534, averageGsp: 7675189, maxGsp: 8165095 },
  { timestamp: 1602618990609, averageGsp: 7671106, maxGsp: 8160751 },
  { timestamp: 1602705402515, averageGsp: 7652323, maxGsp: 8140769 },
  { timestamp: 1602791814813, averageGsp: 7678153, maxGsp: 8168248 },
  { timestamp: 1602878226920, averageGsp: 7675392, maxGsp: 8165311 },
  { timestamp: 1602964639427, averageGsp: 7671591, maxGsp: 8161267 },
  { timestamp: 1603051052121, averageGsp: 7683981, maxGsp: 8174448 },
  { timestamp: 1603137464388, averageGsp: 7690373, maxGsp: 8181248 },
  { timestamp: 1603223877583, averageGsp: 7692801, maxGsp: 8183831 },
  { timestamp: 1603310290326, averageGsp: 7699981, maxGsp: 8191469 },
  { timestamp: 1603396702122, averageGsp: 7660001, maxGsp: 8272801 },
  { timestamp: 1603483114079, averageGsp: 7693562, maxGsp: 8309047 },
  { timestamp: 1603569525790, averageGsp: 7703656, maxGsp: 8319948 },
  { timestamp: 1603655950330, averageGsp: 7706798, maxGsp: 8323342 },
  { timestamp: 1603742363072, averageGsp: 7702433, maxGsp: 8318628 },
  { timestamp: 1603828775222, averageGsp: 7703140, maxGsp: 8319391 },
  { timestamp: 1603915188670, averageGsp: 7703518, maxGsp: 8319799 },
  { timestamp: 1604001600700, averageGsp: 7707154, maxGsp: 8323726 },
  { timestamp: 1604088015870, averageGsp: 7711382, maxGsp: 8328293 },
  { timestamp: 1604174432999, averageGsp: 7708163, maxGsp: 8324816 },
  { timestamp: 1604260844589, averageGsp: 7712738, maxGsp: 8329757 },
  { timestamp: 1604347256672, averageGsp: 7725475, maxGsp: 8343513 },
  { timestamp: 1604433670131, averageGsp: 7728444, maxGsp: 8346720 },
  { timestamp: 1604520083901, averageGsp: 7739742, maxGsp: 8358921 },
  { timestamp: 1604606496551, averageGsp: 7734547, maxGsp: 8353311 },
  { timestamp: 1604692909594, averageGsp: 7737001, maxGsp: 8355961 },
  { timestamp: 1604779326673, averageGsp: 7749716, maxGsp: 8369693 },
  { timestamp: 1604865738441, averageGsp: 7746561, maxGsp: 8366286 },
  { timestamp: 1604952150920, averageGsp: 7746074, maxGsp: 8365760 },
  { timestamp: 1605038565832, averageGsp: 7757607, maxGsp: 8378216 },
  { timestamp: 1605124978354, averageGsp: 7759488, maxGsp: 8380247 },
  { timestamp: 1605211391768, averageGsp: 7752692, maxGsp: 8372907 },
  { timestamp: 1605297804206, averageGsp: 7763946, maxGsp: 8385062 },
  { timestamp: 1605384216188, averageGsp: 7766184, maxGsp: 8387479 },
  { timestamp: 1605470627764, averageGsp: 7773057, maxGsp: 8394902 },
  { timestamp: 1605557039839, averageGsp: 7779669, maxGsp: 8402043 },
  { timestamp: 1605643452458, averageGsp: 7786082, maxGsp: 8408969 },
  { timestamp: 1605729864677, averageGsp: 7781520, maxGsp: 8404042 },
  { timestamp: 1605816291114, averageGsp: 7795891, maxGsp: 8419562 },
  { timestamp: 1605902704419, averageGsp: 7798131, maxGsp: 8421981 },
  { timestamp: 1605989117187, averageGsp: 7789835, maxGsp: 8413022 },
  { timestamp: 1606075533924, averageGsp: 7798395, maxGsp: 8422267 },
  { timestamp: 1606161946026, averageGsp: 7811479, maxGsp: 8436397 },
  { timestamp: 1606248355717, averageGsp: 7807902, maxGsp: 8510613 },
  { timestamp: 1606334768002, averageGsp: 7803106, maxGsp: 8505386 },
  { timestamp: 1606421180217, averageGsp: 7815326, maxGsp: 8518705 },
  { timestamp: 1606507592161, averageGsp: 7827099, maxGsp: 8531538 },
  { timestamp: 1606594008194, averageGsp: 7828227, maxGsp: 8532767 },
  { timestamp: 1606680421575, averageGsp: 7831152, maxGsp: 8535956 },
  { timestamp: 1606766832827, averageGsp: 7846958, maxGsp: 8553184 },
  { timestamp: 1606853246950, averageGsp: 7842930, maxGsp: 8548794 },
  { timestamp: 1606939666343, averageGsp: 7841131, maxGsp: 8546833 },
  { timestamp: 1607026079014, averageGsp: 7843497, maxGsp: 8549412 },
  { timestamp: 1607112491719, averageGsp: 7844697, maxGsp: 8550720 },
  { timestamp: 1607198905701, averageGsp: 7853475, maxGsp: 8560288 },
  { timestamp: 1607285318297, averageGsp: 7860144, maxGsp: 8567557 },
  { timestamp: 1607371730812, averageGsp: 7855442, maxGsp: 8562432 },
  { timestamp: 1607458147922, averageGsp: 7862003, maxGsp: 8569583 },
  { timestamp: 1607544563609, averageGsp: 7876189, maxGsp: 8585046 },
  { timestamp: 1607630976560, averageGsp: 7880602, maxGsp: 8589856 },
  { timestamp: 1607717393047, averageGsp: 7873724, maxGsp: 8582359 },
  { timestamp: 1607803810874, averageGsp: 7889432, maxGsp: 8599481 },
  { timestamp: 1607890229565, averageGsp: 7887389, maxGsp: 8597254 },
  { timestamp: 1607976641935, averageGsp: 7893223, maxGsp: 8603613 },
  { timestamp: 1608063057286, averageGsp: 7899809, maxGsp: 8610792 },
  { timestamp: 1608149479826, averageGsp: 7900908, maxGsp: 8611990 },
  { timestamp: 1608235892726, averageGsp: 7905964, maxGsp: 8617501 },
  { timestamp: 1608322305887, averageGsp: 7923740, maxGsp: 8636877 },
  { timestamp: 1608408718890, averageGsp: 7923078, maxGsp: 8636155 },
  { timestamp: 1608495132126, averageGsp: 7932576, maxGsp: 8646508 },
  { timestamp: 1608508359131, averageGsp: 7934355, maxGsp: 8648447 },
  { timestamp: 1608682611061, averageGsp: 7949249, maxGsp: 8664681 },
  { timestamp: 1609014601910, averageGsp: 7985081, maxGsp: 8703738 },
  { timestamp: 1609061741242, averageGsp: 8003186, maxGsp: 8723473 },
  { timestamp: 1609148234526, averageGsp: 8008119, maxGsp: 8728850 },
  { timestamp: 1609234704071, averageGsp: 8031084, maxGsp: 8753882 },
  { timestamp: 1609321112004, averageGsp: 8049042, maxGsp: 8773456 },
  { timestamp: 1609407712054, averageGsp: 8057604, maxGsp: 8782788 },
  { timestamp: 1609494054479, averageGsp: 8069588, maxGsp: 8795851 },
  { timestamp: 1609580347561, averageGsp: 8081044, maxGsp: 8808338 },
  { timestamp: 1609666839780, averageGsp: 8099754, maxGsp: 8828732 },
  { timestamp: 1609753411148, averageGsp: 8106365, maxGsp: 8835938 },
  { timestamp: 1609839857521, averageGsp: 8115014, maxGsp: 8845365 },
  { timestamp: 1609926263628, averageGsp: 8120145, maxGsp: 8850958 },
  { timestamp: 1610012688512, averageGsp: 8123855, maxGsp: 8855002 },
  { timestamp: 1610099131280, averageGsp: 8145330, maxGsp: 8878410 },
  { timestamp: 1610185604140, averageGsp: 8150313, maxGsp: 8883841 },
  { timestamp: 1610272045521, averageGsp: 8167060, maxGsp: 8902095 },
  { timestamp: 1610358711758, averageGsp: 8180050, maxGsp: 8916255 },
  { timestamp: 1610445149297, averageGsp: 8191925, maxGsp: 8929198 },
  { timestamp: 1610531961720, averageGsp: 8189897, maxGsp: 8926988 },
  { timestamp: 1610618519308, averageGsp: 8189051, maxGsp: 8926066 },
  { timestamp: 1610704895909, averageGsp: 8202462, maxGsp: 8940684 },
  { timestamp: 1610791175748, averageGsp: 8217263, maxGsp: 8956817 },
  { timestamp: 1610877542722, averageGsp: 8227191, maxGsp: 8967638 },
  { timestamp: 1610964163921, averageGsp: 8238654, maxGsp: 8980133 },
  { timestamp: 1611050661149, averageGsp: 8243775, maxGsp: 8985715 },
  { timestamp: 1611137021504, averageGsp: 8256238, maxGsp: 8999299 },
  { timestamp: 1611223473338, averageGsp: 8260310, maxGsp: 9003738 },
  { timestamp: 1611309815177, averageGsp: 8261863, maxGsp: 9005431 },
  { timestamp: 1611395990986, averageGsp: 8271880, maxGsp: 9016349 },
  { timestamp: 1611482414492, averageGsp: 8270651, maxGsp: 9015010 },
  { timestamp: 1611569081693, averageGsp: 8289863, maxGsp: 9035951 },
  { timestamp: 1611653622766, averageGsp: 8289748, maxGsp: 9035825 },
  { timestamp: 1611740037914, averageGsp: 8295040, maxGsp: 9041594 },
  { timestamp: 1611913036479, averageGsp: 8295535, maxGsp: 9042133 },
  { timestamp: 1611999213082, averageGsp: 8318446, maxGsp: 9067106 },
  { timestamp: 1612085618790, averageGsp: 8316293, maxGsp: 9064759 },
  { timestamp: 1612172289917, averageGsp: 8325312, maxGsp: 9074590 },
  { timestamp: 1612258538736, averageGsp: 8333398, maxGsp: 9083404 },
  { timestamp: 1612430837021, averageGsp: 8350407, maxGsp: 9101944 },
  { timestamp: 1612517205153, averageGsp: 8367580, maxGsp: 9120662 },
  { timestamp: 1612776433632, averageGsp: 8373330, maxGsp: 9126930 },
  { timestamp: 1612949234485, averageGsp: 8382922, maxGsp: 9137385 },
  { timestamp: 1613035676389, averageGsp: 8379458, maxGsp: 9133609 },
  { timestamp: 1613122043362, averageGsp: 8396528, maxGsp: 9152216 },
  { timestamp: 1613467650146, averageGsp: 8428331, maxGsp: 9186881 },
  { timestamp: 1613554070416, averageGsp: 8434154, maxGsp: 9193228 },
  { timestamp: 1613640465184, averageGsp: 8427142, maxGsp: 9185585 },
  { timestamp: 1613726865586, averageGsp: 8438763, maxGsp: 9198252 },
  { timestamp: 1613985834111, averageGsp: 8451949, maxGsp: 9212624 },
  { timestamp: 1614072511536, averageGsp: 8462159, maxGsp: 9223753 },
  { timestamp: 1614158909668, averageGsp: 8480208, maxGsp: 9243427 },
  { timestamp: 1614504489604, averageGsp: 8492922, maxGsp: 9257285 },
  { timestamp: 1614763728053, averageGsp: 8519315, maxGsp: 9286053 },
  { timestamp: 1615022931247, averageGsp: 8536689, maxGsp: 9304991 },
  { timestamp: 1615109335898, averageGsp: 8555238, maxGsp: 9325209 },
  { timestamp: 1615195801759, averageGsp: NaN, maxGsp: NaN },
  { timestamp: 1615282458901, averageGsp: 8553177, maxGsp: 9322963 },
  { timestamp: 1615368572807, averageGsp: 8566529, maxGsp: 9337517 },
  { timestamp: 1615714174065, averageGsp: 8596556, maxGsp: 9370246 },
  { timestamp: 1615800560520, averageGsp: 8602821, maxGsp: 9377075 },
  { timestamp: 1615973367015, averageGsp: 8608873, maxGsp: 9383672 },
  { timestamp: 1616059777424, averageGsp: 8616536, maxGsp: 9392024 },
  { timestamp: 1616146177087, averageGsp: 8621107, maxGsp: 9397007 },
  { timestamp: 1616232581574, averageGsp: 8629715, maxGsp: 9406389 },
  { timestamp: 1616318992096, averageGsp: 8637981, maxGsp: 9415399 },
  { timestamp: 1616405746960, averageGsp: 8643699, maxGsp: 9421632 },
  { timestamp: 1616491878198, averageGsp: 8646036, maxGsp: 9424179 },
  { timestamp: 1616577612257, averageGsp: 8667415, maxGsp: 9447482 },
  { timestamp: 1616663714447, averageGsp: 8660029, maxGsp: 9439432 },
  { timestamp: 1616751237621, averageGsp: 8654773, maxGsp: 9433703 },
  { timestamp: 1616837518112, averageGsp: 8665872, maxGsp: 9445800 },
  { timestamp: 1616923943346, averageGsp: 8678808, maxGsp: 9459901 },
  { timestamp: 1617010592779, averageGsp: 8682057, maxGsp: 9463442 },
  { timestamp: 1617096765253, averageGsp: 8679168, maxGsp: 9460293 },
  { timestamp: 1617183185848, averageGsp: 8692088, maxGsp: 9474376 },
  { timestamp: 1617269627450, averageGsp: 8699596, maxGsp: 9482560 },
  { timestamp: 1617356029732, averageGsp: 8696475, maxGsp: 9479158 },
  { timestamp: 1617442323849, averageGsp: 8699819, maxGsp: 9482803 },
  { timestamp: 1617528726285, averageGsp: 8716640, maxGsp: 9501138 },
  { timestamp: 1617615195570, averageGsp: 8715195, maxGsp: 9499563 },
  { timestamp: 1617701565307, averageGsp: 8727264, maxGsp: 9512718 },
  { timestamp: 1617787957321, averageGsp: 8732386, maxGsp: 9518301 },
  { timestamp: 1617874317807, averageGsp: 8729922, maxGsp: 9515615 },
  { timestamp: 1617960795097, averageGsp: 8730815, maxGsp: 9516588 },
  { timestamp: 1618047082663, averageGsp: 8748964, maxGsp: 9536371 },
  { timestamp: 1618133482110, averageGsp: 8761775, maxGsp: 9550335 },
  { timestamp: 1618219979420, averageGsp: 8758017, maxGsp: 9546239 },
  { timestamp: 1618306372487, averageGsp: 8752445, maxGsp: 9540165 },
  { timestamp: 1618392661990, averageGsp: 8764527, maxGsp: 9553334 },
  { timestamp: 1618479107612, averageGsp: 8778927, maxGsp: 9569030 },
  { timestamp: 1618565531723, averageGsp: 8773269, maxGsp: 9562863 },
  { timestamp: 1618651866404, averageGsp: 8773798, maxGsp: 9563440 },
  { timestamp: 1618738287741, averageGsp: 8779313, maxGsp: 9569451 },
  { timestamp: 1618824738839, averageGsp: 8779895, maxGsp: 9570086 },
  { timestamp: 1618911148479, averageGsp: 8812866, maxGsp: 9606024 },
  { timestamp: 1618997566253, averageGsp: 8797541, maxGsp: 9589320 },
  { timestamp: 1619083993767, averageGsp: 8797367, maxGsp: 9589130 },
  { timestamp: 1619170455745, averageGsp: 8809128, maxGsp: 9601950 },
  { timestamp: 1619256754382, averageGsp: 8815843, maxGsp: 9609269 },
  { timestamp: 1619343268681, averageGsp: 8812053, maxGsp: 9605138 },
  { timestamp: 1619429659510, averageGsp: 8830348, maxGsp: 9625079 },
  { timestamp: 1619515969172, averageGsp: 8824991, maxGsp: 9619240 },
  { timestamp: 1619602400927, averageGsp: 8829799, maxGsp: 9624481 },
  { timestamp: 1619688733933, averageGsp: 8841081, maxGsp: 9636778 },
  { timestamp: 1619775141783, averageGsp: 8831703, maxGsp: 9626556 },
  { timestamp: 1619861553011, averageGsp: 8841548, maxGsp: 9637287 },
  { timestamp: 1619947905236, averageGsp: 8847716, maxGsp: 9644010 },
  { timestamp: 1620034354685, averageGsp: 8847403, maxGsp: 9643669 },
  { timestamp: 1620120731495, averageGsp: 8857317, maxGsp: 9654476 },
  { timestamp: 1620207086491, averageGsp: 8857625, maxGsp: 9654811 },
  { timestamp: 1620293529582, averageGsp: 8884183, maxGsp: 9683759 },
  { timestamp: 1620379868869, averageGsp: 8878632, maxGsp: 9677709 },
  { timestamp: 1620466244258, averageGsp: 8877996, maxGsp: 9677016 },
  { timestamp: 1620552697415, averageGsp: 8879281, maxGsp: 9678416 },
  { timestamp: 1620639175098, averageGsp: 8897418, maxGsp: 9698186 },
  { timestamp: 1620725540281, averageGsp: 8910652, maxGsp: 9712611 },
  { timestamp: 1620811989079, averageGsp: 8894073, maxGsp: 9694540 },
  { timestamp: 1620898462523, averageGsp: 8895544, maxGsp: 9696143 },
  { timestamp: 1620984882361, averageGsp: 8896927, maxGsp: 9697650 },
  { timestamp: 1621071194574, averageGsp: 8897910, maxGsp: 9698722 },
  { timestamp: 1621244075057, averageGsp: 8931306, maxGsp: 9735124 },
  { timestamp: 1621330469003, averageGsp: 8925398, maxGsp: 9728684 },
  { timestamp: 1621416830944, averageGsp: 8935490, maxGsp: 9739684 },
  { timestamp: 1621503236932, averageGsp: 8933003, maxGsp: 9736973 },
  { timestamp: 1621589686438, averageGsp: 8930239, maxGsp: 9733961 },
  { timestamp: 1621676049689, averageGsp: 8933923, maxGsp: 9737976 },
  { timestamp: 1621762410756, averageGsp: 8946210, maxGsp: 9751369 },
  { timestamp: 1621848980368, averageGsp: 8940281, maxGsp: 9744906 },
  { timestamp: 1621935610970, averageGsp: 8955206, maxGsp: 9761175 },
  { timestamp: 1622022269834, averageGsp: 8950765, maxGsp: 9756334 },
  { timestamp: 1622109242790, averageGsp: 8952887, maxGsp: 9758647 },
  { timestamp: 1622195723102, averageGsp: 8968077, maxGsp: 9775204 },
  { timestamp: 1622282474066, averageGsp: 8970941, maxGsp: 9778326 },
  { timestamp: 1622368657422, averageGsp: 8980363, maxGsp: 9788596 },
  { timestamp: 1622455916411, averageGsp: 8969027, maxGsp: 9776239 },
  { timestamp: 1622542221513, averageGsp: 8976303, maxGsp: 9784170 },
  { timestamp: 1622630360195, averageGsp: 8996419, maxGsp: 9806097 },
  { timestamp: 1622715083590, averageGsp: 8982647, maxGsp: 9791085 },
  { timestamp: 1622803217445, averageGsp: 8995266, maxGsp: 9804840 },
  { timestamp: 1622887022019, averageGsp: 8996554, maxGsp: 9806244 },
  { timestamp: 1622973449020, averageGsp: 8991647, maxGsp: 9800895 },
  { timestamp: 1623060791269, averageGsp: 9000249, maxGsp: 9810271 },
  { timestamp: 1623144800386, averageGsp: 9003000, maxGsp: 9813270 },
  { timestamp: 1623231021575, averageGsp: 8997095, maxGsp: 9806834 },
  { timestamp: 1623317417737, averageGsp: 9010781, maxGsp: 9821751 },
  { timestamp: 1623490064258, averageGsp: 9006708, maxGsp: 9817312 },
  { timestamp: 1623576508372, averageGsp: 9013592, maxGsp: 9824815 },
  { timestamp: 1623662960449, averageGsp: 9025161, maxGsp: 9837425 },
  { timestamp: 1623749356334, averageGsp: 9049961, maxGsp: 9864457 },
  { timestamp: 1623835761823, averageGsp: 9042527, maxGsp: 9856354 },
  { timestamp: 1623922208605, averageGsp: 9050651, maxGsp: 9865210 },
  { timestamp: 1624008557916, averageGsp: 9042995, maxGsp: 9856865 },
  { timestamp: 1624094946584, averageGsp: 9054490, maxGsp: 9869394 },
  { timestamp: 1624181320373, averageGsp: 9067555, maxGsp: 9883635 },
  { timestamp: 1624267758866, averageGsp: 9064227, maxGsp: 9880007 },
  { timestamp: 1624354168958, averageGsp: 9066080, maxGsp: 9882027 },
  { timestamp: 1624440440252, averageGsp: 9062217, maxGsp: 9877817 },
  { timestamp: 1624526795318, averageGsp: 9075268, maxGsp: 9892042 },
  { timestamp: 1624613228738, averageGsp: 9059178, maxGsp: 9874504 },
  { timestamp: 1624699605269, averageGsp: 9052515, maxGsp: 9867241 },
  { timestamp: 1624786017669, averageGsp: 9051383, maxGsp: 9866007 },
  { timestamp: 1624872472606, averageGsp: 9099376, maxGsp: 9918320 },
  { timestamp: 1624958884395, averageGsp: 9086860, maxGsp: 9904677 },
  { timestamp: 1625045285182, averageGsp: 9087829, maxGsp: 9905734 },
  { timestamp: 1625131647737, averageGsp: 9084113, maxGsp: 9901683 },
  { timestamp: 1625218039261, averageGsp: 9091594, maxGsp: 9909837 },
  { timestamp: 1625304362833, averageGsp: 9101656, maxGsp: 9920805 },
  { timestamp: 1625390765053, averageGsp: 9093022, maxGsp: 9911394 },
  { timestamp: 1625477279196, averageGsp: 9115445, maxGsp: 9935835 },
  { timestamp: 1625563586212, averageGsp: 9109055, maxGsp: 9928870 },
  { timestamp: 1625822811356, averageGsp: 9140038, maxGsp: 9962641 },
  { timestamp: 1625909174349, averageGsp: 9146060, maxGsp: 9969205 },
  { timestamp: 1626082012813, averageGsp: 9154599, maxGsp: 9978513 },
  { timestamp: 1626168403284, averageGsp: 9142317, maxGsp: 9965126 },
  { timestamp: 1626254798985, averageGsp: 9158098, maxGsp: 9982327 },
  { timestamp: 1626427610662, averageGsp: 9172995, maxGsp: 9998565 },
  { timestamp: 1626513980368, averageGsp: 9174287, maxGsp: 9999973 },
  { timestamp: 1626600375586, averageGsp: 9189726, maxGsp: 10016801 },
  { timestamp: 1626686821401, averageGsp: 9185211, maxGsp: 10011880 },
  { timestamp: 1626773203919, averageGsp: 9198345, maxGsp: 10026196 },
  { timestamp: 1626859626509, averageGsp: 9198738, maxGsp: 10026624 },
  { timestamp: 1626946006868, averageGsp: 9207373, maxGsp: 10036037 },
  { timestamp: 1627118784341, averageGsp: 9207782, maxGsp: 10036482 },
  { timestamp: 1627292375237, averageGsp: 9211072, maxGsp: 10040068 },
  { timestamp: 1627637186147, averageGsp: 9237639, maxGsp: 10069027 },
  { timestamp: 1627723573657, averageGsp: 9257891, maxGsp: 10091101 },
  { timestamp: 1627809961377, averageGsp: 9255656, maxGsp: 10088665 },
  { timestamp: 1627982779865, averageGsp: 9264704, maxGsp: 10098527 },
  { timestamp: 1628069176600, averageGsp: 9251208, maxGsp: 10083817 },
  { timestamp: 1628241955179, averageGsp: 9270418, maxGsp: 10104756 },
  { timestamp: 1628328319889, averageGsp: 9282357, maxGsp: 10117769 },
  { timestamp: 1628587571804, averageGsp: 9289744, maxGsp: 10125821 },
  { timestamp: 1628674005451, averageGsp: 9278126, maxGsp: 10113157 },
  { timestamp: 1628760353935, averageGsp: 9306862, maxGsp: 10144480 },
  { timestamp: 1628846727046, averageGsp: 9294043, maxGsp: 10130507 },
  { timestamp: 1629019507895, averageGsp: 9324495, maxGsp: 10163700 },
  { timestamp: 1629105939906, averageGsp: 9325950, maxGsp: 10165286 },
  { timestamp: 1629192341472, averageGsp: 9329092, maxGsp: 10168710 },
  { timestamp: 1629365118073, averageGsp: 9337298, maxGsp: 10177655 },
  { timestamp: 1629451503601, averageGsp: 9345716, maxGsp: 10186830 },
  { timestamp: 1629710652170, averageGsp: 9369521, maxGsp: 10212778 },
  { timestamp: 1629883459643, averageGsp: 9369891, maxGsp: 10213181 },
  { timestamp: 1629969870858, averageGsp: 9378846, maxGsp: 10222942 },
  { timestamp: 1630056243390, averageGsp: 9370535, maxGsp: 10213883 },
  { timestamp: 1630142601378, averageGsp: 9366639, maxGsp: 10209637 },
  { timestamp: 1630315469723, averageGsp: 9381512, maxGsp: 10225848 },
  { timestamp: 1630401872735, averageGsp: 9380316, maxGsp: 10224544 },
  { timestamp: 1630488262745, averageGsp: 9380025, maxGsp: 10224227 },
  { timestamp: 1630833800992, averageGsp: 9405544, maxGsp: 10252043 },
  { timestamp: 1630920268685, averageGsp: 9400007, maxGsp: 10246008 },
  { timestamp: 1631006647626, averageGsp: 9414029, maxGsp: 10261292 },
  { timestamp: 1631179451666, averageGsp: 9411759, maxGsp: 10258817 },
  { timestamp: 1631265843144, averageGsp: 9451547, maxGsp: 10302186 },
  { timestamp: 1631438598162, averageGsp: 9416807, maxGsp: 10264320 },
  { timestamp: 1631525046239, averageGsp: 9431666, maxGsp: 10280516 },
  { timestamp: 1631870621713, averageGsp: 9435625, maxGsp: 10284831 },
  { timestamp: 1632043393561, averageGsp: 9456337, maxGsp: 10307407 },
  { timestamp: 1632129831622, averageGsp: 9472242, maxGsp: 10324744 },
  { timestamp: 1632302649920, averageGsp: 9462016, maxGsp: 10313597 },
  { timestamp: 1632389061490, averageGsp: 9469184, maxGsp: 10321411 },
  { timestamp: 1632475434245, averageGsp: 9469352, maxGsp: 10321594 },
  { timestamp: 1632734641258, averageGsp: 9491585, maxGsp: 10345828 },
  { timestamp: 1632821039599, averageGsp: 9489111, maxGsp: 10343131 },
  { timestamp: 1632907431102, averageGsp: 9474828, maxGsp: 10327563 },
  { timestamp: 1633512264092, averageGsp: 9522501, maxGsp: 10379526 },
  { timestamp: 1633598562354, averageGsp: 9515871, maxGsp: 10372299 },
  { timestamp: 1633685042209, averageGsp: 9523864, maxGsp: 10381012 },
  { timestamp: 1633771407989, averageGsp: 9519934, maxGsp: 10376728 },
  { timestamp: 1633857785158, averageGsp: 9538058, maxGsp: 10396483 },
  { timestamp: 1634289860673, averageGsp: 9550010, maxGsp: 10409511 },
  { timestamp: 1634462641514, averageGsp: 9578837, maxGsp: 10440932 },
  { timestamp: 1634635482606, averageGsp: 9599759, maxGsp: 10463737 },
  { timestamp: 1634894682006, averageGsp: 9596855, maxGsp: 10460572 },
  { timestamp: 1634981054814, averageGsp: 9601302, maxGsp: 10465419 },
  { timestamp: 1635067464899, averageGsp: 9610186, maxGsp: 10475103 },
  { timestamp: 1635326693886, averageGsp: 9616346, maxGsp: 10481817 },
  { timestamp: 1635413110139, averageGsp: 9624311, maxGsp: 10490499 },
  { timestamp: 1635499485428, averageGsp: 9648738, maxGsp: 10517124 },
  { timestamp: 1635672261941, averageGsp: 9653548, maxGsp: 10522367 },
  { timestamp: 1636104317205, averageGsp: 9678909, maxGsp: 10550011 },
  { timestamp: 1636449941627, averageGsp: 9700203, maxGsp: 10573221 },
  { timestamp: 1636622732136, averageGsp: 9696102, maxGsp: 10568751 },
  { timestamp: 1636709146473, averageGsp: 9704582, maxGsp: 10577994 },
  { timestamp: 1636795497156, averageGsp: 9704903, maxGsp: 10578344 },
  { timestamp: 1636881964614, averageGsp: 9706427, maxGsp: 10580005 },
  { timestamp: 1636968362545, averageGsp: 9716748, maxGsp: 10591255 },
  { timestamp: 1637054734898, averageGsp: 9730692, maxGsp: 10606454 },
  { timestamp: 1637141117172, averageGsp: 9704555, maxGsp: 10577965 },
  { timestamp: 1637227549174, averageGsp: 9707278, maxGsp: 10580933 },
  { timestamp: 1637313948483, averageGsp: 9730351, maxGsp: 10606083 },
  { timestamp: 1637400310420, averageGsp: 9727120, maxGsp: 10602561 },
  { timestamp: 1637486703830, averageGsp: 9723965, maxGsp: 10599122 },
  { timestamp: 1637573158289, averageGsp: 9733755, maxGsp: 10609793 },
  { timestamp: 1637659562245, averageGsp: 9755160, maxGsp: 10633124 },
  { timestamp: 1637832345854, averageGsp: 9737647, maxGsp: 10614035 },
  { timestamp: 1637918740289, averageGsp: 9765910, maxGsp: 10644842 },
  { timestamp: 1638091507524, averageGsp: 9748546, maxGsp: 10625915 },
  { timestamp: 1639560342941, averageGsp: 9836642, maxGsp: 10721940 },
  { timestamp: 1639733137238, averageGsp: 9850232, maxGsp: 10736753 },
  { timestamp: 1640078765970, averageGsp: 9862511, maxGsp: 10750137 },
  { timestamp: 1640165162394, averageGsp: 9851655, maxGsp: 10738304 },
  { timestamp: 1640251567782, averageGsp: 9867072, maxGsp: 10755108 },
  { timestamp: 1640337970890, averageGsp: 9872224, maxGsp: 10760724 },
  { timestamp: 1640424316992, averageGsp: 9887925, maxGsp: 10777838 },
  { timestamp: 1640683560677, averageGsp: 9878388, maxGsp: 10767443 },
  { timestamp: 1640856353789, averageGsp: 9942743, maxGsp: 10837590 },
  { timestamp: 1640942765930, averageGsp: 9942804, maxGsp: 10837656 },
  { timestamp: 1641115516854, averageGsp: 9967882, maxGsp: 10864991 },
  { timestamp: 1641202008721, averageGsp: 9976527, maxGsp: 10874414 },
  { timestamp: 1641288361329, averageGsp: 9976922, maxGsp: 10874845 },
  { timestamp: 1641374784727, averageGsp: 9999125, maxGsp: 10899046 },
  { timestamp: 1641633935601, averageGsp: 10006005, maxGsp: 10906545 },
  { timestamp: 1641806822666, averageGsp: 10046613, maxGsp: 10950808 },
  { timestamp: 1642325138822, averageGsp: NaN, maxGsp: NaN },
  { timestamp: 1642411646780, averageGsp: NaN, maxGsp: NaN },
  { timestamp: 1642498001739, averageGsp: 10073249, maxGsp: 10979841 },
  { timestamp: 1642670678448, averageGsp: 10080817, maxGsp: 10988091 },
  { timestamp: 1642757036844, averageGsp: 10085825, maxGsp: 10993549 },
  { timestamp: 1642843378325, averageGsp: 10080291, maxGsp: 10987517 },
  { timestamp: 1642929768603, averageGsp: 10111102, maxGsp: 11021101 },
  { timestamp: 1643102633949, averageGsp: 10158323, maxGsp: 11072572 },
  { timestamp: 1643189081535, averageGsp: 10126353, maxGsp: 11037725 },
  { timestamp: 1643275445620, averageGsp: 10105090, maxGsp: 11014548 },
  { timestamp: 1643448189217, averageGsp: 10103394, maxGsp: 11012699 },
  { timestamp: 1643707451953, averageGsp: 10133565, maxGsp: 11045586 },
  { timestamp: 1643793798049, averageGsp: 10119249, maxGsp: 11029981 },
  { timestamp: 1643880223042, averageGsp: 10119837, maxGsp: 11030622 },
  { timestamp: 1644052847850, averageGsp: 10157890, maxGsp: 11072100 },
  { timestamp: 1644139417719, averageGsp: 10139130, maxGsp: 11051652 },
  { timestamp: 1644398614625, averageGsp: 10154324, maxGsp: 11169756 },
  { timestamp: 1644485008703, averageGsp: 10148472, maxGsp: 11163319 },
  { timestamp: 1644571435033, averageGsp: 10147751, maxGsp: 11162526 },
  { timestamp: 1644830582463, averageGsp: 10185744, maxGsp: 11204318 },
  { timestamp: 1644917004546, averageGsp: 10188645, maxGsp: 11207510 },
  { timestamp: 1645003371462, averageGsp: 10186456, maxGsp: 11205102 },
  { timestamp: 1645176202642, averageGsp: 10191363, maxGsp: 11210499 },
  { timestamp: 1645262088802, averageGsp: 10185971, maxGsp: 11204568 },
  { timestamp: 1645348472391, averageGsp: 10211262, maxGsp: 11232388 },
  { timestamp: 1645780481965, averageGsp: 10220640, maxGsp: 11242704 },
  { timestamp: 1645953266921, averageGsp: 10240137, maxGsp: 11264151 },
  { timestamp: 1646212473143, averageGsp: 10249074, maxGsp: 11273981 },
  { timestamp: 1646298887437, averageGsp: 10260110, maxGsp: 11286121 },
  { timestamp: 1646471670931, averageGsp: 10259087, maxGsp: 11284996 },
  { timestamp: 1646558070741, averageGsp: 10270764, maxGsp: 11297840 },
  { timestamp: 1646644483447, averageGsp: 10271335, maxGsp: 11298469 },
  { timestamp: 1646817281080, averageGsp: 10277014, maxGsp: 11304715 },
  { timestamp: 1646903689923, averageGsp: 10280563, maxGsp: 11308619 },
  { timestamp: 1646990082357, averageGsp: 10287441, maxGsp: 11316185 },
  { timestamp: 1647076470574, averageGsp: 10296195, maxGsp: 11325815 },
  { timestamp: 1647422090636, averageGsp: 10319628, maxGsp: 11351591 },
  { timestamp: 1647767679132, averageGsp: 10330149, maxGsp: 11363164 },
  { timestamp: 1647854085902, averageGsp: 10319656, maxGsp: 11351622 },
  { timestamp: 1648026878321, averageGsp: 10363427, maxGsp: 11399770 },
  { timestamp: 1648286065418, averageGsp: 10348264, maxGsp: 11383090 },
  { timestamp: 1648372463003, averageGsp: 10358492, maxGsp: 11394341 },
  { timestamp: 1648458889301, averageGsp: 10349217, maxGsp: 11384139 },
  { timestamp: 1648804471942, averageGsp: 10384467, maxGsp: 11422914 },
  { timestamp: 1648890871379, averageGsp: 10385459, maxGsp: 11424005 },
  { timestamp: 1648977281654, averageGsp: 10379415, maxGsp: 11417357 },
  { timestamp: 1649322886455, averageGsp: 10422334, maxGsp: 11516679 },
  { timestamp: 1649409290864, averageGsp: 10390898, maxGsp: 11481942 },
  { timestamp: 1649495677040, averageGsp: 10421764, maxGsp: 11516049 },
  { timestamp: 1649582077990, averageGsp: 10416907, maxGsp: 11510682 },
  { timestamp: 1649841285447, averageGsp: 10430501, maxGsp: 11525704 },
  { timestamp: 1649927688413, averageGsp: 10431022, maxGsp: 11526279 },
  { timestamp: 1650100479791, averageGsp: 10454901, maxGsp: 11552666 },
  { timestamp: 1650273291950, averageGsp: 10464428, maxGsp: 11563193 },
  { timestamp: 1650446094218, averageGsp: 10464102, maxGsp: 11562833 },
  { timestamp: 1650791684294, averageGsp: 10476638, maxGsp: 11576685 },
  { timestamp: 1650964502914, averageGsp: 10483292, maxGsp: 11584038 },
  { timestamp: 1651050917692, averageGsp: 10473938, maxGsp: 11573701 },
  { timestamp: 1651137347779, averageGsp: 10476001, maxGsp: 11575981 },
  { timestamp: 1651310075689, averageGsp: 10490498, maxGsp: 11592000 },
  { timestamp: 1651655711477, averageGsp: 10521822, maxGsp: 11626613 },
  { timestamp: 1651828497026, averageGsp: 10517759, maxGsp: 11622124 },
  { timestamp: 1651914889827, averageGsp: 10524674, maxGsp: 11629765 },
  { timestamp: 1652001290977, averageGsp: 10547266, maxGsp: 11654729 },
  { timestamp: 1652260554844, averageGsp: 10548935, maxGsp: 11656573 },
  { timestamp: 1652433297921, averageGsp: 10570905, maxGsp: 11680850 },
  { timestamp: 1652519729827, averageGsp: 10576460, maxGsp: 11686988 },
  { timestamp: 1652606107759, averageGsp: 10561597, maxGsp: 11670565 },
  { timestamp: 1652692538104, averageGsp: 10578834, maxGsp: 11689612 },
  { timestamp: 1652778935668, averageGsp: 10565177, maxGsp: 11674521 },
  { timestamp: 1652865403943, averageGsp: 10565223, maxGsp: 11674571 },
  { timestamp: 1652951711523, averageGsp: 10589860, maxGsp: 11701795 },
  { timestamp: 1653038110730, averageGsp: 10593160, maxGsp: 11705442 },
  { timestamp: 1653210882055, averageGsp: 10587758, maxGsp: 11699473 },
  { timestamp: 1653297301258, averageGsp: 10583105, maxGsp: 11694331 },
  { timestamp: 1653383713446, averageGsp: 10616247, maxGsp: 11730953 },
  { timestamp: 1653729306538, averageGsp: 10614378, maxGsp: 11728888 },
  { timestamp: 1653815697884, averageGsp: 10626008, maxGsp: 11741739 },
  { timestamp: 1653902126397, averageGsp: 10631010, maxGsp: 11747266 },
  { timestamp: 1654161305754, averageGsp: 10640645, maxGsp: 11757913 },
  { timestamp: 1654247713578, averageGsp: 10644831, maxGsp: 11762538 },
  { timestamp: 1654334080745, averageGsp: 10650990, maxGsp: 11769344 },
  { timestamp: 1654506886005, averageGsp: 10651916, maxGsp: 11770367 },
  { timestamp: 1654766101228, averageGsp: 10685220, maxGsp: 11807168 },
  { timestamp: 1654938870359, averageGsp: 10687197, maxGsp: 11809353 },
  { timestamp: 1655025284756, averageGsp: 10688301, maxGsp: 11810573 },
  { timestamp: 1655111723772, averageGsp: 10700405, maxGsp: 11823948 },
  { timestamp: 1655198124731, averageGsp: 10669612, maxGsp: 11789921 },
  { timestamp: 1655284491431, averageGsp: 10689273, maxGsp: 11811647 },
  { timestamp: 1655370905378, averageGsp: 10710038, maxGsp: 11834592 },
  { timestamp: 1655457394277, averageGsp: 10704317, maxGsp: 11828270 },
  { timestamp: 1655543679046, averageGsp: 10699431, maxGsp: 11822871 },
  { timestamp: 1655630093599, averageGsp: 10702957, maxGsp: 11826767 },
  { timestamp: 1655716504108, averageGsp: 10714227, maxGsp: 11839221 },
  { timestamp: 1655803554199, averageGsp: 10732528, maxGsp: 11859443 },
  { timestamp: 1655889292306, averageGsp: 10725956, maxGsp: 11852181 },
  { timestamp: 1655975690501, averageGsp: 10715445, maxGsp: 11840567 },
  { timestamp: 1656062079028, averageGsp: 10719263, maxGsp: 11844786 },
  { timestamp: 1656148480522, averageGsp: 10743613, maxGsp: 11871692 },
  { timestamp: 1656234876477, averageGsp: 10749061, maxGsp: 11877712 },
  { timestamp: 1656321302694, averageGsp: 10750911, maxGsp: 11879757 },
  { timestamp: 1656407691713, averageGsp: 10755106, maxGsp: 11884392 },
  { timestamp: 1656494099921, averageGsp: 10761650, maxGsp: 11891623 },
  { timestamp: 1656580488739, averageGsp: 10752352, maxGsp: 11881349 },
  { timestamp: 1656666907100, averageGsp: 10763358, maxGsp: 11893511 },
  { timestamp: 1656753277858, averageGsp: 10800275, maxGsp: 11934304 },
  { timestamp: 1656839683129, averageGsp: 10749708, maxGsp: 11878427 },
  { timestamp: 1656926130377, averageGsp: 10777099, maxGsp: 11908694 },
  { timestamp: 1657012485456, averageGsp: 10755199, maxGsp: 11884495 },
  { timestamp: 1657098911539, averageGsp: 10750562, maxGsp: 11879371 },
  { timestamp: 1657185297378, averageGsp: 10780118, maxGsp: 11912030 },
  { timestamp: 1657271719532, averageGsp: 10780992, maxGsp: 11912996 },
  { timestamp: 1657358086538, averageGsp: 10783825, maxGsp: 11916127 },
  { timestamp: 1657444471689, averageGsp: 10797132, maxGsp: 11930831 },
  { timestamp: 1657531489695, averageGsp: 10793364, maxGsp: 11926667 },
  { timestamp: 1657617625917, averageGsp: 10750562, maxGsp: 11879371 },
  { timestamp: 1657703699699, averageGsp: 10813393, maxGsp: 11948799 },
  { timestamp: 1657790119177, averageGsp: 10819511, maxGsp: 11955560 },
  { timestamp: 1657876490128, averageGsp: 10824241, maxGsp: 11960786 },
  { timestamp: 1657962889311, averageGsp: 10827813, maxGsp: 11964733 },
  { timestamp: 1658049282923, averageGsp: 10833772, maxGsp: 11971318 },
  { timestamp: 1658135689450, averageGsp: 10837703, maxGsp: 11975662 },
  { timestamp: 1658222390183, averageGsp: 10840556, maxGsp: 11978814 },
  { timestamp: 1658308517019, averageGsp: 10847924, maxGsp: 11986956 },
  { timestamp: 1658394948175, averageGsp: 10851161, maxGsp: 11990533 },
  { timestamp: 1658481306419, averageGsp: 10854413, maxGsp: 11994126 },
  { timestamp: 1658567684197, averageGsp: 10860015, maxGsp: 12000317 },
  { timestamp: 1658654087023, averageGsp: 10862479, maxGsp: 12003039 },
  { timestamp: 1658740493829, averageGsp: 10869647, maxGsp: 12010960 },
  { timestamp: 1658826886495, averageGsp: 10879044, maxGsp: 12021344 },
  { timestamp: 1658913290145, averageGsp: 10882392, maxGsp: 12025043 },
  { timestamp: 1658999703849, averageGsp: 10892318, maxGsp: 12036011 },
  { timestamp: 1659086112567, averageGsp: 10893508, maxGsp: 12037326 },
  { timestamp: 1659172469658, averageGsp: 10891385, maxGsp: 12034980 },
  { timestamp: 1659258869930, averageGsp: 10899770, maxGsp: 12044246 },
  { timestamp: 1659345302780, averageGsp: 10901743, maxGsp: 12046426 },
  { timestamp: 1659431679484, averageGsp: 10906316, maxGsp: 12051479 },
  { timestamp: 1659518114055, averageGsp: 10910138, maxGsp: 12055702 },
  { timestamp: 1659604483706, averageGsp: 10907565, maxGsp: 12052859 },
  { timestamp: 1659690897426, averageGsp: 10911710, maxGsp: 12057440 },
  { timestamp: 1659777282883, averageGsp: 10916466, maxGsp: 12062695 },
  { timestamp: 1659863675173, averageGsp: 10917426, maxGsp: 12063756 },
  { timestamp: 1659950099278, averageGsp: 10922384, maxGsp: 12069234 },
  { timestamp: 1660036524761, averageGsp: 10923833, maxGsp: 12070835 },
  { timestamp: 1660122902213, averageGsp: 10927998, maxGsp: 12075438 },
  { timestamp: 1660209327547, averageGsp: 10928317, maxGsp: 12075790 },
  { timestamp: 1660295705457, averageGsp: 10934441, maxGsp: 12082557 },
  { timestamp: 1660382073376, averageGsp: 10939696, maxGsp: 12088364 },
  { timestamp: 1660468495407, averageGsp: 10947609, maxGsp: 12097108 },
  { timestamp: 1660554896642, averageGsp: 10951482, maxGsp: 12101388 },
  { timestamp: 1660641297974, averageGsp: 10954181, maxGsp: 12104370 },
  { timestamp: 1660727739635, averageGsp: 10955326, maxGsp: 12105635 },
  { timestamp: 1660814188189, averageGsp: 10965716, maxGsp: 12117116 },
  { timestamp: 1660900507903, averageGsp: 10970466, maxGsp: 12122365 },
  { timestamp: 1660986883723, averageGsp: 10973181, maxGsp: 12125365 },
  { timestamp: 1661073290246, averageGsp: 10974123, maxGsp: 12126406 },
  { timestamp: 1661159708206, averageGsp: 10977657, maxGsp: 12130311 },
  { timestamp: 1661246098560, averageGsp: 10982439, maxGsp: 12135595 },
  { timestamp: 1661332487582, averageGsp: 10984117, maxGsp: 12137449 },
  { timestamp: 1661418889402, averageGsp: 10984545, maxGsp: 12137922 },
  { timestamp: 1661505305527, averageGsp: 10987980, maxGsp: 12141718 },
  { timestamp: 1661591695031, averageGsp: 10992639, maxGsp: 12146866 },
  { timestamp: 1661678092957, averageGsp: 10999730, maxGsp: 12154702 },
  { timestamp: 1661764497896, averageGsp: 10999646, maxGsp: 12154609 },
  { timestamp: 1661850889672, averageGsp: 10999961, maxGsp: 12154957 },
  { timestamp: 1661937298019, averageGsp: 11007080, maxGsp: 12162823 },
  { timestamp: 1662023705689, averageGsp: 11012976, maxGsp: 12169338 },
  { timestamp: 1662110090496, averageGsp: 11018568, maxGsp: 12175518 },
  { timestamp: 1662196510849, averageGsp: 11022971, maxGsp: 12180383 },
  { timestamp: 1662282889797, averageGsp: 11026901, maxGsp: 12184726 },
  { timestamp: 1662369319166, averageGsp: 11032765, maxGsp: 12191205 },
  { timestamp: 1662455696347, averageGsp: 11038552, maxGsp: 12197600 },
  { timestamp: 1662542113571, averageGsp: 11040895, maxGsp: 12200189 },
  { timestamp: 1662628514780, averageGsp: 11040570, maxGsp: 12199830 },
  { timestamp: 1662714928928, averageGsp: 11042361, maxGsp: 12201809 },
  { timestamp: 1662801298358, averageGsp: 11045838, maxGsp: 12205651 },
  { timestamp: 1662887701812, averageGsp: 11048659, maxGsp: 12208768 },
  { timestamp: 1662974151380, averageGsp: 11050597, maxGsp: 12210910 },
  { timestamp: 1663060517904, averageGsp: 11052431, maxGsp: 12212936 },
  { timestamp: 1663146922606, averageGsp: 11057206, maxGsp: 12218213 },
  { timestamp: 1663233333915, averageGsp: 11066003, maxGsp: 12227933 },
  { timestamp: 1663319717259, averageGsp: 11070729, maxGsp: 12233156 },
  { timestamp: 1663406109143, averageGsp: 11075110, maxGsp: 12237997 },
  { timestamp: 1663492491342, averageGsp: 11080544, maxGsp: 12244001 },
  { timestamp: 1663578970683, averageGsp: 11083802, maxGsp: 12247601 },
  { timestamp: 1663665356425, averageGsp: 11084790, maxGsp: 12248693 },
  { timestamp: 1663751737386, averageGsp: 11086076, maxGsp: 12250114 },
  { timestamp: 1663838160861, averageGsp: 11084125, maxGsp: 12247958 },
  { timestamp: 1663924568567, averageGsp: 11085862, maxGsp: 12249878 },
  { timestamp: 1664010920405, averageGsp: 11091282, maxGsp: 12255867 },
  { timestamp: 1664097320796, averageGsp: 11090378, maxGsp: 12254868 },
  { timestamp: 1664183771865, averageGsp: 11089665, maxGsp: 12254080 },
  { timestamp: 1664270212875, averageGsp: 11095004, maxGsp: 12259979 },
  { timestamp: 1664356590600, averageGsp: 11102570, maxGsp: 12268340 },
  { timestamp: 1664442993593, averageGsp: 11110690, maxGsp: 12277312 },
  { timestamp: 1664529424382, averageGsp: 11116482, maxGsp: 12283713 },
  { timestamp: 1664615758860, averageGsp: 11119707, maxGsp: 12287276 },
  { timestamp: 1664702135155, averageGsp: 11125195, maxGsp: 12293340 },
  { timestamp: 1664788616726, averageGsp: 11129128, maxGsp: 12297686 },
  { timestamp: 1664874985070, averageGsp: 11131630, maxGsp: 12300451 },
  { timestamp: 1664961347299, averageGsp: 11138322, maxGsp: 12307846 },
  { timestamp: 1665047783539, averageGsp: 11140159, maxGsp: 12309876 },
  { timestamp: 1665134168105, averageGsp: 11138697, maxGsp: 12308260 },
  { timestamp: 1665220542688, averageGsp: 11139558, maxGsp: 12309212 },
  { timestamp: 1665306943475, averageGsp: 11144853, maxGsp: 12315063 },
  { timestamp: 1665393398989, averageGsp: 11150288, maxGsp: 12321068 },
  { timestamp: 1665479771784, averageGsp: 11150690, maxGsp: 12321512 },
  { timestamp: 1665566171519, averageGsp: 11148541, maxGsp: 12319138 },
  { timestamp: 1665652578741, averageGsp: 11155242, maxGsp: 12326542 },
  { timestamp: 1665738985784, averageGsp: 11165239, maxGsp: 12337589 },
  { timestamp: 1665825340310, averageGsp: 11169478, maxGsp: 12342273 },
  { timestamp: 1665911747799, averageGsp: 11170254, maxGsp: 12343131 },
  { timestamp: 1665998380761, averageGsp: 11172693, maxGsp: 12345826 },
  { timestamp: 1666084628562, averageGsp: 11178533, maxGsp: 12352279 },
  { timestamp: 1666171072734, averageGsp: 11183070, maxGsp: 12357292 },
  { timestamp: 1666257399864, averageGsp: 11182541, maxGsp: 12356708 },
  { timestamp: 1666343784194, averageGsp: 11186122, maxGsp: 12360665 },
  { timestamp: 1666430181969, averageGsp: 11184693, maxGsp: 12359086 },
  { timestamp: 1666516537929, averageGsp: 11185496, maxGsp: 12359973 },
  { timestamp: 1666603145962, averageGsp: 11190856, maxGsp: 12365896 },
  { timestamp: 1666689465426, averageGsp: 11195562, maxGsp: 12371096 },
  { timestamp: 1666775696784, averageGsp: 11194807, maxGsp: 12370262 },
  { timestamp: 1666862120420, averageGsp: 11198462, maxGsp: 12374301 },
  { timestamp: 1666948520085, averageGsp: 11196149, maxGsp: 12371745 },
  { timestamp: 1667034904819, averageGsp: 11206509, maxGsp: 12383192 },
  { timestamp: 1667121296007, averageGsp: 11211840, maxGsp: 12389083 },
  { timestamp: 1667207732735, averageGsp: 11210497, maxGsp: 12387599 },
  { timestamp: 1667294131626, averageGsp: 11212408, maxGsp: 12389711 },
  { timestamp: 1667380515443, averageGsp: 11214869, maxGsp: 12392430 },
  { timestamp: 1667466919552, averageGsp: 11215540, maxGsp: 12393172 },
  { timestamp: 1667553293425, averageGsp: 11218018, maxGsp: 12395910 },
  { timestamp: 1667639696828, averageGsp: 11216297, maxGsp: 12394008 },
  { timestamp: 1667726100924, averageGsp: 11223132, maxGsp: 12401561 },
  { timestamp: 1667812509076, averageGsp: 11231236, maxGsp: 12410516 },
  { timestamp: 1667898897190, averageGsp: 11233530, maxGsp: 12413051 },
  { timestamp: 1667985317629, averageGsp: 11239524, maxGsp: 12419674 },
  { timestamp: 1668071708929, averageGsp: 11244135, maxGsp: 12424769 },
  { timestamp: 1668158100436, averageGsp: 11249516, maxGsp: 12430715 },
  { timestamp: 1668244494414, averageGsp: 11252031, maxGsp: 12433494 },
  { timestamp: 1668330905132, averageGsp: 11253252, maxGsp: 12434843 },
  { timestamp: 1668417310456, averageGsp: 11253787, maxGsp: 12435435 },
  { timestamp: 1668503693160, averageGsp: 11257075, maxGsp: 12439068 },
  { timestamp: 1668590111068, averageGsp: 11257468, maxGsp: 12439502 },
  { timestamp: 1668676491682, averageGsp: 11256085, maxGsp: 12437974 },
  { timestamp: 1668762907099, averageGsp: 11254741, maxGsp: 12436489 },
  { timestamp: 1668849276402, averageGsp: 11254570, maxGsp: 12436300 },
  { timestamp: 1668935687462, averageGsp: 11257143, maxGsp: 12439143 },
  { timestamp: 1669022101327, averageGsp: 11261089, maxGsp: 12443503 },
  { timestamp: 1669108506090, averageGsp: 11264848, maxGsp: 12447657 },
  { timestamp: 1669194874094, averageGsp: 11266490, maxGsp: 12449471 },
  { timestamp: 1669281289423, averageGsp: 11270929, maxGsp: 12454377 },
  { timestamp: 1669367681525, averageGsp: 11275492, maxGsp: 12459419 },
  { timestamp: 1669454061839, averageGsp: 11280180, maxGsp: 12464599 },
  { timestamp: 1669540460860, averageGsp: 11285781, maxGsp: 12470788 },
  { timestamp: 1669626899371, averageGsp: 11285404, maxGsp: 12470371 },
  { timestamp: 1669713272001, averageGsp: 11288136, maxGsp: 12473390 },
  { timestamp: 1669799691202, averageGsp: 11295496, maxGsp: 12481523 },
  { timestamp: 1669886077366, averageGsp: 11294596, maxGsp: 12480529 },
  { timestamp: 1669972467489, averageGsp: 11300677, maxGsp: 12487248 },
  { timestamp: 1670058857252, averageGsp: 11301435, maxGsp: 12488086 },
  { timestamp: 1670145256084, averageGsp: 11302611, maxGsp: 12489385 },
  { timestamp: 1670231681383, averageGsp: 11305718, maxGsp: 12492818 },
  { timestamp: 1670318094585, averageGsp: 11304234, maxGsp: 12491179 },
  { timestamp: 1670404467040, averageGsp: 11306352, maxGsp: 12493519 },
  { timestamp: 1670490858991, averageGsp: 11315997, maxGsp: 12504177 },
  { timestamp: 1670577271651, averageGsp: 11317038, maxGsp: 12505327 },
  { timestamp: 1670663652956, averageGsp: 11318861, maxGsp: 12507341 },
  { timestamp: 1670750058981, averageGsp: 11323089, maxGsp: 12512013 },
  { timestamp: 1670836463585, averageGsp: 11328863, maxGsp: 12518394 },
  { timestamp: 1670922876447, averageGsp: 11329502, maxGsp: 12519100 },
  { timestamp: 1671009263951, averageGsp: 11330698, maxGsp: 12520421 },
  { timestamp: 1671095685704, averageGsp: 11333189, maxGsp: 12523174 },
  { timestamp: 1671182046566, averageGsp: 11337409, maxGsp: 12527837 },
  { timestamp: 1671268444844, averageGsp: 11337257, maxGsp: 12527669 },
  { timestamp: 1671354852249, averageGsp: 11331862, maxGsp: 12521708 },
  { timestamp: 1671441262920, averageGsp: 11331848, maxGsp: 12521692 },
  { timestamp: 1671527668795, averageGsp: 11340192, maxGsp: 12530912 },
  { timestamp: 1671614055085, averageGsp: 11343221, maxGsp: 12534259 },
  { timestamp: 1671700457249, averageGsp: 11339003, maxGsp: 12529598 },
  { timestamp: 1671786863031, averageGsp: 11343795, maxGsp: 12534893 },
  { timestamp: 1671873241583, averageGsp: 11351294, maxGsp: 12543180 },
  { timestamp: 1671959640817, averageGsp: 11365514, maxGsp: 12558893 },
  { timestamp: 1672046052030, averageGsp: 11372794, maxGsp: 12566937 },
  { timestamp: 1672132454759, averageGsp: 11371465, maxGsp: 12565469 },
  { timestamp: 1672218848276, averageGsp: 11380658, maxGsp: 12575627 },
  { timestamp: 1672305255400, averageGsp: 11390652, maxGsp: 12586670 },
  { timestamp: 1672391667959, averageGsp: 11393334, maxGsp: 12589634 },
  { timestamp: 1672478043489, averageGsp: 11401294, maxGsp: 12598430 },
  { timestamp: 1672564448570, averageGsp: 11408183, maxGsp: 12606042 },
  { timestamp: 1672650873790, averageGsp: 11413164, maxGsp: 12611546 },
  { timestamp: 1672737263251, averageGsp: 11422789, maxGsp: 12622182 },
  { timestamp: 1672823654708, averageGsp: 11429832, maxGsp: 12629964 },
  { timestamp: 1672910158368, averageGsp: 11431252, maxGsp: 12631533 },
  { timestamp: 1672996480599, averageGsp: 11441686, maxGsp: 12643063 },
  { timestamp: 1673082860933, averageGsp: 11450378, maxGsp: 12652668 },
  { timestamp: 1673169267177, averageGsp: 11452055, maxGsp: 12654521 },
  { timestamp: 1673255683339, averageGsp: 11460108, maxGsp: 12663419 },
  { timestamp: 1673342064340, averageGsp: 11464891, maxGsp: 12668705 },
  { timestamp: 1673428495293, averageGsp: 11474416, maxGsp: 12679230 },
  { timestamp: 1673514914855, averageGsp: 11482614, maxGsp: 12688288 },
  { timestamp: 1673601291116, averageGsp: 11488662, maxGsp: 12694972 },
  { timestamp: 1673687664066, averageGsp: 11493949, maxGsp: 12700814 },
  { timestamp: 1673774057247, averageGsp: 11495997, maxGsp: 12703077 },
  { timestamp: 1673860475220, averageGsp: 11502380, maxGsp: 12710130 },
  { timestamp: 1673946864627, averageGsp: 11508053, maxGsp: 12716399 },
  { timestamp: 1674033259802, averageGsp: 11511113, maxGsp: 12719780 },
  { timestamp: 1674119668753, averageGsp: 11512178, maxGsp: 12720957 },
  { timestamp: 1674206074580, averageGsp: 11514121, maxGsp: 12723104 },
  { timestamp: 1674292476020, averageGsp: 11521579, maxGsp: 12731345 },
  { timestamp: 1674378875758, averageGsp: 11524698, maxGsp: 12734791 },
  { timestamp: 1674465269617, averageGsp: 11524658, maxGsp: 12734747 },
  { timestamp: 1674551671629, averageGsp: 11529442, maxGsp: 12740033 },
  { timestamp: 1674638055653, averageGsp: 11534038, maxGsp: 12745112 },
  { timestamp: 1674724476768, averageGsp: 11539858, maxGsp: 12751543 },
  { timestamp: 1674810861195, averageGsp: 11544165, maxGsp: 12756302 },
  { timestamp: 1674897257398, averageGsp: 11551024, maxGsp: 12763882 },
  { timestamp: 1674983661161, averageGsp: 11554512, maxGsp: 12767736 },
  { timestamp: 1675070077930, averageGsp: 11558189, maxGsp: 12771799 },
  { timestamp: 1675156462900, averageGsp: 11565297, maxGsp: 12779653 },
  { timestamp: 1675242874061, averageGsp: 11574463, maxGsp: 12789782 },
  { timestamp: 1675329278578, averageGsp: 11577827, maxGsp: 12793499 },
  { timestamp: 1675415670275, averageGsp: 11577990, maxGsp: 12793679 },
  { timestamp: 1675502045963, averageGsp: 11580083, maxGsp: 12795992 },
  { timestamp: 1675588482279, averageGsp: 11586282, maxGsp: 12802842 },
  { timestamp: 1675674883380, averageGsp: 11588546, maxGsp: 12805343 },
  { timestamp: 1675761269364, averageGsp: 11590369, maxGsp: 12807358 },
  { timestamp: 1675847691594, averageGsp: 11595272, maxGsp: 12812776 },
  { timestamp: 1675934070122, averageGsp: 11598388, maxGsp: 12816219 },
  { timestamp: 1676020514248, averageGsp: 11604566, maxGsp: 12823045 },
  { timestamp: 1676106850067, averageGsp: 11606044, maxGsp: 12824679 },
  { timestamp: 1676193262065, averageGsp: 11610194, maxGsp: 12829264 },
  { timestamp: 1676279685252, averageGsp: 11611680, maxGsp: 12830906 },
  { timestamp: 1676366090142, averageGsp: 11610564, maxGsp: 12829673 },
  { timestamp: 1676452484518, averageGsp: 11614110, maxGsp: 12833592 },
  { timestamp: 1676538914312, averageGsp: 11615732, maxGsp: 12835384 },
  { timestamp: 1676625272003, averageGsp: 11617124, maxGsp: 12836922 },
  { timestamp: 1676711667034, averageGsp: 11616242, maxGsp: 12835947 },
  { timestamp: 1676798052282, averageGsp: 11623732, maxGsp: 12844224 },
  { timestamp: 1676884490804, averageGsp: 11634499, maxGsp: 12856121 },
  { timestamp: 1676970886601, averageGsp: 11639310, maxGsp: 12861438 },
  { timestamp: 1677057276942, averageGsp: 11640705, maxGsp: 12862979 },
  { timestamp: 1677143675553, averageGsp: 11645316, maxGsp: 12868074 },
  { timestamp: 1677230079113, averageGsp: 11649861, maxGsp: 12873096 },
  { timestamp: 1677316466060, averageGsp: 11657298, maxGsp: 12881314 },
  { timestamp: 1677402860708, averageGsp: 11660126, maxGsp: 12884439 },
  { timestamp: 1677489294116, averageGsp: 11664831, maxGsp: 12889638 },
  { timestamp: 1677575685166, averageGsp: 11668705, maxGsp: 12893919 },
  { timestamp: 1677662085943, averageGsp: 11673226, maxGsp: 12898915 },
  { timestamp: 1677748471004, averageGsp: 11675781, maxGsp: 12901738 },
  { timestamp: 1677834876616, averageGsp: 11682243, maxGsp: 12908879 },
  { timestamp: 1677921268870, averageGsp: 11687362, maxGsp: 12914535 },
  { timestamp: 1678007663765, averageGsp: 11691174, maxGsp: 12918747 },
  { timestamp: 1678094108296, averageGsp: 11693964, maxGsp: 12921830 },
  { timestamp: 1678180502088, averageGsp: 11694668, maxGsp: 12922608 },
  { timestamp: 1678266882547, averageGsp: 11696461, maxGsp: 12924589 },
  { timestamp: 1678353314321, averageGsp: 11697816, maxGsp: 12926087 },
  { timestamp: 1678439678974, averageGsp: 11700849, maxGsp: 12929438 },
  { timestamp: 1678526042489, averageGsp: 11704666, maxGsp: 12933656 },
  { timestamp: 1678612448153, averageGsp: 11705533, maxGsp: 12934614 },
  { timestamp: 1678785297631, averageGsp: 11716828, maxGsp: 12947095 },
  { timestamp: 1678871678807, averageGsp: 11719569, maxGsp: 12950124 },
  { timestamp: 1678958105440, averageGsp: 11719886, maxGsp: 12950474 },
  { timestamp: 1679044468042, averageGsp: 11720493, maxGsp: 12951145 },
  { timestamp: 1679130843642, averageGsp: 11723027, maxGsp: 12953945 },
  { timestamp: 1679217262122, averageGsp: 11723643, maxGsp: 12954626 },
  { timestamp: 1679303682093, averageGsp: 11727975, maxGsp: 12959412 },
  { timestamp: 1679390060408, averageGsp: 11732347, maxGsp: 12964243 },
  { timestamp: 1679476452538, averageGsp: 11733968, maxGsp: 12966035 },
  { timestamp: 1679562858016, averageGsp: 11739336, maxGsp: 12971966 },
  { timestamp: 1679649278062, averageGsp: 11748337, maxGsp: 12981912 },
  { timestamp: 1679735641879, averageGsp: 11752955, maxGsp: 12987015 },
  { timestamp: 1679822038070, averageGsp: 11762733, maxGsp: 12997820 },
  { timestamp: 1679908495086, averageGsp: 11766844, maxGsp: 13002363 },
  { timestamp: 1679994889408, averageGsp: 11768653, maxGsp: 13004362 },
  { timestamp: 1680081291965, averageGsp: 11774352, maxGsp: 13010659 },
  { timestamp: 1680167685490, averageGsp: 11776058, maxGsp: 13012544 },
  { timestamp: 1680254059010, averageGsp: 11779663, maxGsp: 13016528 },
  { timestamp: 1680340435204, averageGsp: 11781194, maxGsp: 13018219 },
  { timestamp: 1680426837227, averageGsp: 11782178, maxGsp: 13019307 },
  { timestamp: 1680513251536, averageGsp: 11784955, maxGsp: 13022375 },
  { timestamp: 1680599661828, averageGsp: 11790518, maxGsp: 13028522 },
  { timestamp: 1680686068493, averageGsp: 11796088, maxGsp: 13034677 },
  { timestamp: 1680772438839, averageGsp: 11797814, maxGsp: 13036584 },
  { timestamp: 1680858849398, averageGsp: 11803853, maxGsp: 13043258 },
  { timestamp: 1680945250702, averageGsp: 11810325, maxGsp: 13050409 },
  { timestamp: 1681031626142, averageGsp: 11812335, maxGsp: 13052630 },
  { timestamp: 1681118077002, averageGsp: 11818955, maxGsp: 13059945 },
  { timestamp: 1681204466040, averageGsp: 11827601, maxGsp: 13069499 },
  { timestamp: 1681290863866, averageGsp: 11832645, maxGsp: 13075073 },
  { timestamp: 1681377255123, averageGsp: 11841938, maxGsp: 13085341 },
  { timestamp: 1681463648520, averageGsp: 11842294, maxGsp: 13085735 },
  { timestamp: 1681550028151, averageGsp: 11847525, maxGsp: 13091515 },
  { timestamp: 1681636432892, averageGsp: 11851510, maxGsp: 13095919 },
  { timestamp: 1681722878595, averageGsp: 11854934, maxGsp: 13099702 },
  { timestamp: 1681809265341, averageGsp: 11859408, maxGsp: 13104646 },
  { timestamp: 1681895651964, averageGsp: 11863748, maxGsp: 13109442 },
  { timestamp: 1681982075833, averageGsp: 11865349, maxGsp: 13111211 },
  { timestamp: 1682068447167, averageGsp: 11870408, maxGsp: 13116801 },
  { timestamp: 1682154832166, averageGsp: 11869923, maxGsp: 13116265 },
  { timestamp: 1682241241010, averageGsp: 11878073, maxGsp: 13125271 },
  { timestamp: 1682414060682, averageGsp: 11885756, maxGsp: 13133760 },
  { timestamp: 1682500455242, averageGsp: 11885721, maxGsp: 13133722 },
  { timestamp: 1682586844735, averageGsp: 11887770, maxGsp: 13135986 },
  { timestamp: 1682673245067, averageGsp: 11890890, maxGsp: 13139433 },
  { timestamp: 1682759653937, averageGsp: 11895872, maxGsp: 13144939 },
  { timestamp: 1682846049933, averageGsp: 11898773, maxGsp: 13148144 },
  { timestamp: 1682932463977, averageGsp: 11895844, maxGsp: 13144908 },
  { timestamp: 1683018865706, averageGsp: 11904891, maxGsp: 13154905 },
  { timestamp: 1683105253087, averageGsp: 11911198, maxGsp: 13161874 },
  { timestamp: 1683191644321, averageGsp: 11916792, maxGsp: 13168055 },
  { timestamp: 1683278040817, averageGsp: 11924318, maxGsp: 13176371 },
  { timestamp: 1683364450833, averageGsp: 11926711, maxGsp: 13179016 },
  { timestamp: 1683450830517, averageGsp: 11930081, maxGsp: 13182740 },
  { timestamp: 1683537254512, averageGsp: 11937388, maxGsp: 13190814 },
  { timestamp: 1683623655805, averageGsp: 11940164, maxGsp: 13193881 },
  { timestamp: 1683710049848, averageGsp: 11943830, maxGsp: 13197932 },
  { timestamp: 1683796435862, averageGsp: 11952489, maxGsp: 13207500 },
  { timestamp: 1683882837378, averageGsp: 11955661, maxGsp: 13211005 },
  { timestamp: 1683969220027, averageGsp: 11958030, maxGsp: 13213623 },
  { timestamp: 1684055642815, averageGsp: 11965112, maxGsp: 13221449 },
  { timestamp: 1684142055792, averageGsp: 11971659, maxGsp: 13228683 },
  { timestamp: 1684228445884, averageGsp: 11978516, maxGsp: 13236260 },
  { timestamp: 1684314870749, averageGsp: 11979282, maxGsp: 13237107 },
  { timestamp: 1684401245706, averageGsp: 11981092, maxGsp: 13239107 },
  { timestamp: 1684487652847, averageGsp: 11985804, maxGsp: 13244313 },
  { timestamp: 1684574030445, averageGsp: 11989895, maxGsp: 13248834 },
  { timestamp: 1684660444724, averageGsp: 11986771, maxGsp: 13245382 },
  { timestamp: 1684746871336, averageGsp: 11986767, maxGsp: 13245378 },
  { timestamp: 1684833245867, averageGsp: 11991562, maxGsp: 13250676 },
  { timestamp: 1684919641128, averageGsp: 11992255, maxGsp: 13251442 },
  { timestamp: 1685006044661, averageGsp: 11997944, maxGsp: 13257728 },
  { timestamp: 1685092450617, averageGsp: 12002547, maxGsp: 13262814 },
  { timestamp: 1685178826224, averageGsp: 12004642, maxGsp: 13265129 },
  { timestamp: 1685265237293, averageGsp: 12009287, maxGsp: 13270262 },
  { timestamp: 1685351665225, averageGsp: 12016275, maxGsp: 13277984 },
  { timestamp: 1685438055428, averageGsp: 12019140, maxGsp: 13281150 },
  { timestamp: 1685524480765, averageGsp: 12027577, maxGsp: 13290473 },
  { timestamp: 1685610859125, averageGsp: 12033162, maxGsp: 13296644 },
  { timestamp: 1685697243172, averageGsp: 12040473, maxGsp: 13304723 },
  { timestamp: 1685783628111, averageGsp: 12043800, maxGsp: 13308399 },
  { timestamp: 1685870062501, averageGsp: 12046043, maxGsp: 13310878 },
  { timestamp: 1685956457929, averageGsp: 12051749, maxGsp: 13317183 },
  { timestamp: 1686042841039, averageGsp: 12058630, maxGsp: 13324786 },
  { timestamp: 1686129234116, averageGsp: 12060872, maxGsp: 13327264 },
  { timestamp: 1686215643540, averageGsp: 12064870, maxGsp: 13331681 },
  { timestamp: 1686302052595, averageGsp: 12066430, maxGsp: 13333405 },
  { timestamp: 1686388435359, averageGsp: 12071501, maxGsp: 13339009 },
  { timestamp: 1686474824881, averageGsp: 12078071, maxGsp: 13346268 },
  { timestamp: 1686561291773, averageGsp: 12084521, maxGsp: 13353396 },
  { timestamp: 1686647640643, averageGsp: 12088935, maxGsp: 13358273 },
  { timestamp: 1686734038384, averageGsp: 12086290, maxGsp: 13355350 },
  { timestamp: 1686820482700, averageGsp: 12087518, maxGsp: 13356707 },
  { timestamp: 1686906868698, averageGsp: 12090946, maxGsp: 13360495 },
  { timestamp: 1686993234898, averageGsp: 12097091, maxGsp: 13367286 },
  { timestamp: 1687079644959, averageGsp: 12100759, maxGsp: 13371339 },
  { timestamp: 1687166060609, averageGsp: 12103548, maxGsp: 13374421 },
  { timestamp: 1687252463392, averageGsp: 12104793, maxGsp: 13375796 },
  { timestamp: 1687338848706, averageGsp: 12108762, maxGsp: 13380182 },
  { timestamp: 1687425235379, averageGsp: 12112493, maxGsp: 13384305 },
  { timestamp: 1687511710781, averageGsp: 12116924, maxGsp: 13389201 },
  { timestamp: 1687598073597, averageGsp: 12117463, maxGsp: 13389797 },
  { timestamp: 1687684461952, averageGsp: 12120006, maxGsp: 13392607 },
  { timestamp: 1687770895594, averageGsp: 12124513, maxGsp: 13397587 },
  { timestamp: 1687857291465, averageGsp: 12131204, maxGsp: 13404980 },
  { timestamp: 1687943681756, averageGsp: 12135132, maxGsp: 13409321 },
  { timestamp: 1688030082616, averageGsp: 12137896, maxGsp: 13412375 },
  { timestamp: 1688116471390, averageGsp: 12140357, maxGsp: 13415094 },
  { timestamp: 1688202870958, averageGsp: 12147214, maxGsp: 13422671 },
  { timestamp: 1688289258593, averageGsp: 12150099, maxGsp: 13425859 },
  { timestamp: 1688375670569, averageGsp: 12154072, maxGsp: 13430250 },
  { timestamp: 1688462078491, averageGsp: 12156096, maxGsp: 13432486 },
  { timestamp: 1688548495425, averageGsp: 12158023, maxGsp: 13434615 },
  { timestamp: 1688634893784, averageGsp: 12162641, maxGsp: 13439718 },
  { timestamp: 1688721270151, averageGsp: 12172405, maxGsp: 13450508 },
  { timestamp: 1688807647063, averageGsp: 12176394, maxGsp: 13454915 },
  { timestamp: 1688894053946, averageGsp: 12181960, maxGsp: 13461066 },
  { timestamp: 1688980483728, averageGsp: 12186386, maxGsp: 13465957 },
  { timestamp: 1689066880594, averageGsp: 12191762, maxGsp: 13471897 },
  { timestamp: 1689153264337, averageGsp: 12198515, maxGsp: 13479359 },
  { timestamp: 1689239680281, averageGsp: 12195278, maxGsp: 13475782 },
  { timestamp: 1689326062696, averageGsp: 12197415, maxGsp: 13478144 },
  { timestamp: 1689412454633, averageGsp: 12206096, maxGsp: 13487736 },
  { timestamp: 1689498846241, averageGsp: 12212640, maxGsp: 13494967 },
  { timestamp: 1689585295462, averageGsp: 12213787, maxGsp: 13496235 },
  { timestamp: 1689671666097, averageGsp: 12218169, maxGsp: 13501077 },
  { timestamp: 1689758044623, averageGsp: 12221934, maxGsp: 13505237 },
  { timestamp: 1689844442035, averageGsp: 12228898, maxGsp: 13512932 },
  { timestamp: 1689930834562, averageGsp: 12230264, maxGsp: 13514442 },
  { timestamp: 1690017235203, averageGsp: 12230697, maxGsp: 13514920 },
  { timestamp: 1690103632246, averageGsp: 12237629, maxGsp: 13522580 },
  { timestamp: 1690190058873, averageGsp: 12240440, maxGsp: 13525686 },
  { timestamp: 1690276462641, averageGsp: 12238260, maxGsp: 13523277 },
  { timestamp: 1690362843561, averageGsp: 12244669, maxGsp: 13530359 },
  { timestamp: 1690449254143, averageGsp: 12246362, maxGsp: 13532230 },
  { timestamp: 1690535632692, averageGsp: 12252835, maxGsp: 13539383 },
  { timestamp: 1690622015024, averageGsp: 12258435, maxGsp: 13545571 },
  { timestamp: 1690708419008, averageGsp: 12258811, maxGsp: 13545986 },
  { timestamp: 1690794865060, averageGsp: 12261794, maxGsp: 13549282 },
  { timestamp: 1690881259143, averageGsp: 12265846, maxGsp: 13553760 },
  { timestamp: 1690967642533, averageGsp: 12270060, maxGsp: 13558416 },
  { timestamp: 1691054061778, averageGsp: 12275223, maxGsp: 13564121 },
  { timestamp: 1691140436319, averageGsp: 12276988, maxGsp: 13566072 },
  { timestamp: 1691226820323, averageGsp: 12280996, maxGsp: 13570501 },
  { timestamp: 1691313220198, averageGsp: 12284303, maxGsp: 13574155 },
  { timestamp: 1691399667682, averageGsp: 12284703, maxGsp: 13574597 },
  { timestamp: 1691486037228, averageGsp: 12287000, maxGsp: 13577135 },
  { timestamp: 1691572451670, averageGsp: 12294095, maxGsp: 13584975 },
  { timestamp: 1691658857733, averageGsp: 12298521, maxGsp: 13589866 },
  { timestamp: 1691745221903, averageGsp: 12299909, maxGsp: 13591399 },
  { timestamp: 1691831621858, averageGsp: 12300961, maxGsp: 13592562 },
  { timestamp: 1691918015650, averageGsp: 12302480, maxGsp: 13594240 },
  { timestamp: 1692004454699, averageGsp: 12307354, maxGsp: 13599626 },
  { timestamp: 1692090822249, averageGsp: 12312893, maxGsp: 13605747 },
  { timestamp: 1692177237421, averageGsp: 12317203, maxGsp: 13610509 },
  { timestamp: 1692263636150, averageGsp: 12320622, maxGsp: 13614287 },
  { timestamp: 1692350024729, averageGsp: 12326321, maxGsp: 13620585 },
  { timestamp: 1692436411561, averageGsp: 12329591, maxGsp: 13624198 },
  { timestamp: 1692522813850, averageGsp: 12335567, maxGsp: 13630802 },
  { timestamp: 1692609253840, averageGsp: 12340055, maxGsp: 13635761 },
  { timestamp: 1692695632946, averageGsp: 12345616, maxGsp: 13641906 },
  { timestamp: 1692782048401, averageGsp: 12347309, maxGsp: 13643776 },
  { timestamp: 1692868440001, averageGsp: 12349325, maxGsp: 13646004 },
  { timestamp: 1692954827322, averageGsp: 12353233, maxGsp: 13650322 },
  { timestamp: 1693041212924, averageGsp: 12357361, maxGsp: 13654884 },
  { timestamp: 1693127625208, averageGsp: 12361123, maxGsp: 13659041 },
  { timestamp: 1693214050093, averageGsp: 12361130, maxGsp: 13659049 },
  { timestamp: 1693300434057, averageGsp: 12362307, maxGsp: 13660349 },
  { timestamp: 1693386830415, averageGsp: 12366911, maxGsp: 13665437 },
  { timestamp: 1693473233724, averageGsp: 12371017, maxGsp: 13669974 },
  { timestamp: 1693559643245, averageGsp: 12375027, maxGsp: 13674405 },
  { timestamp: 1693646014172, averageGsp: 12379968, maxGsp: 13679865 },
  { timestamp: 1693732426775, averageGsp: 12384350, maxGsp: 13684707 },
  { timestamp: 1693818847912, averageGsp: 12388110, maxGsp: 13688862 },
  { timestamp: 1693905239895, averageGsp: 12392109, maxGsp: 13693280 },
  { timestamp: 1693991658806, averageGsp: 12391282, maxGsp: 13692367 },
  { timestamp: 1694078049172, averageGsp: 12394522, maxGsp: 13695947 },
  { timestamp: 1694164439444, averageGsp: 12398141, maxGsp: 13699946 },
  { timestamp: 1694250813475, averageGsp: 12404465, maxGsp: 13706934 },
  { timestamp: 1694337225893, averageGsp: 12405681, maxGsp: 13708278 },
  { timestamp: 1694423654658, averageGsp: 12412498, maxGsp: 13715810 },
  { timestamp: 1694510040877, averageGsp: 12412537, maxGsp: 13715853 },
  { timestamp: 1694596453257, averageGsp: 12412167, maxGsp: 13715445 },
  { timestamp: 1694682838834, averageGsp: 12418817, maxGsp: 13722793 },
  { timestamp: 1694769245876, averageGsp: 12417115, maxGsp: 13720912 },
  { timestamp: 1694855630059, averageGsp: 12415815, maxGsp: 13719476 },
  { timestamp: 1694942044685, averageGsp: 12420364, maxGsp: 13724502 },
  { timestamp: 1695028450095, averageGsp: 12426299, maxGsp: 13731060 },
  { timestamp: 1695114842051, averageGsp: 12430561, maxGsp: 13735770 },
  { timestamp: 1695201251630, averageGsp: 12433065, maxGsp: 13738537 },
  { timestamp: 1695287661067, averageGsp: 12438110, maxGsp: 13744112 },
  { timestamp: 1695374041311, averageGsp: 12442451, maxGsp: 13748908 },
  { timestamp: 1695460430971, averageGsp: 12447083, maxGsp: 13754027 },
  { timestamp: 1695546821533, averageGsp: 12451562, maxGsp: 13758976 },
  { timestamp: 1695633254659, averageGsp: 12455708, maxGsp: 13763557 },
  { timestamp: 1695719644431, averageGsp: 12455946, maxGsp: 13763820 },
  { timestamp: 1695806044625, averageGsp: 12454333, maxGsp: 13762038 },
  { timestamp: 1695892446199, averageGsp: 12454038, maxGsp: 13761712 },
  { timestamp: 1695978856551, averageGsp: 12457823, maxGsp: 13765894 },
  { timestamp: 1696065211663, averageGsp: 12459038, maxGsp: 13767237 },
  { timestamp: 1696151618817, averageGsp: 12462182, maxGsp: 13770711 },
  { timestamp: 1696238075863, averageGsp: 12458422, maxGsp: 13766556 },
  { timestamp: 1696324446312, averageGsp: 12462157, maxGsp: 13770683 },
  { timestamp: 1696410856353, averageGsp: 12467205, maxGsp: 13776262 },
  { timestamp: 1696497253238, averageGsp: 12470090, maxGsp: 13779449 },
  { timestamp: 1696583657108, averageGsp: 12469418, maxGsp: 13778707 },
  { timestamp: 1696670015724, averageGsp: 12466943, maxGsp: 13775972 },
  { timestamp: 1696756421551, averageGsp: 12471987, maxGsp: 13781546 },
  { timestamp: 1696842864795, averageGsp: 12478474, maxGsp: 13788714 },
  { timestamp: 1696929247873, averageGsp: 12481393, maxGsp: 13791939 },
  { timestamp: 1697015650151, averageGsp: 12479020, maxGsp: 13789317 },
  { timestamp: 1697102077261, averageGsp: 12483324, maxGsp: 13794073 },
  { timestamp: 1697188472564, averageGsp: 12488009, maxGsp: 13799250 },
  { timestamp: 1697274827399, averageGsp: 12498083, maxGsp: 13810382 },
  { timestamp: 1697361222184, averageGsp: 12502678, maxGsp: 13815459 },
  { timestamp: 1697447688984, averageGsp: 12503883, maxGsp: 13816791 },
  { timestamp: 1697534060864, averageGsp: 12508135, maxGsp: 13821489 },
  { timestamp: 1697620460018, averageGsp: 12515797, maxGsp: 13829956 },
  { timestamp: 1697706860036, averageGsp: 12515291, maxGsp: 13829397 },
  { timestamp: 1697793242207, averageGsp: 12515393, maxGsp: 13829509 },
  { timestamp: 1697879620105, averageGsp: 12512919, maxGsp: 13826775 },
  { timestamp: 1697966023638, averageGsp: 12514216, maxGsp: 13828209 },
  { timestamp: 1698052475595, averageGsp: 12513102, maxGsp: 13826978 },
  { timestamp: 1698138872011, averageGsp: 12515761, maxGsp: 13829916 },
  { timestamp: 1698225256903, averageGsp: 12518467, maxGsp: 13832906 },
  { timestamp: 1698311657918, averageGsp: 12514722, maxGsp: 13828768 },
  { timestamp: 1698398043607, averageGsp: 12511886, maxGsp: 13825634 },
  { timestamp: 1698484427171, averageGsp: 12512904, maxGsp: 13826759 },
  { timestamp: 1698570824962, averageGsp: 12527194, maxGsp: 13842549 },
  { timestamp: 1698657260549, averageGsp: 12535383, maxGsp: 13851598 },
  { timestamp: 1698743640685, averageGsp: 12537757, maxGsp: 13854221 },
  { timestamp: 1698830065918, averageGsp: 12535551, maxGsp: 13851784 },
  { timestamp: 1698916441855, averageGsp: 12539454, maxGsp: 13856097 },
  { timestamp: 1699002856224, averageGsp: 12544072, maxGsp: 13861200 },
  { timestamp: 1699089228811, averageGsp: 12551074, maxGsp: 13868937 },
  { timestamp: 1699175633453, averageGsp: 12551421, maxGsp: 13869320 },
  { timestamp: 1699262076716, averageGsp: 12553054, maxGsp: 13871125 },
  { timestamp: 1699348442647, averageGsp: 12553382, maxGsp: 13871487 },
  { timestamp: 1699434832627, averageGsp: 12560243, maxGsp: 13879069 },
  { timestamp: 1699521244074, averageGsp: 12561218, maxGsp: 13880146 },
  { timestamp: 1699607640922, averageGsp: 12562121, maxGsp: 13881144 },
  { timestamp: 1699694025667, averageGsp: 12560168, maxGsp: 13878986 },
  { timestamp: 1699780424948, averageGsp: 12563416, maxGsp: 13882575 },
  { timestamp: 1699866852077, averageGsp: 12563436, maxGsp: 13882597 },
  { timestamp: 1699953245589, averageGsp: 12562463, maxGsp: 13881522 },
  { timestamp: 1700039657909, averageGsp: 12562314, maxGsp: 13881357 },
  { timestamp: 1700126050761, averageGsp: 12569293, maxGsp: 13889069 },
  { timestamp: 1700212452728, averageGsp: 12571605, maxGsp: 13891624 },
  { timestamp: 1700298828328, averageGsp: 12578177, maxGsp: 13898886 },
  { timestamp: 1700385220495, averageGsp: 12577594, maxGsp: 13898241 },
  { timestamp: 1700471673512, averageGsp: 12582203, maxGsp: 13903334 },
  { timestamp: 1700558050719, averageGsp: 12588563, maxGsp: 13910362 },
  { timestamp: 1700644454989, averageGsp: 12593242, maxGsp: 13915532 },
  { timestamp: 1700730850074, averageGsp: 12597336, maxGsp: 13920056 },
  { timestamp: 1700817250510, averageGsp: 12602391, maxGsp: 13925642 },
  { timestamp: 1700903624493, averageGsp: 12598666, maxGsp: 13921526 },
  { timestamp: 1700990026524, averageGsp: 12603150, maxGsp: 13926481 },
  { timestamp: 1701076477369, averageGsp: 12607567, maxGsp: 13931362 },
  { timestamp: 1701162845711, averageGsp: 12611628, maxGsp: 13935849 },
  { timestamp: 1701249250140, averageGsp: 12607840, maxGsp: 13931663 },
  { timestamp: 1701335651677, averageGsp: 12606735, maxGsp: 13930442 },
  { timestamp: 1701422045969, averageGsp: 12613795, maxGsp: 13938243 },
  { timestamp: 1701508426201, averageGsp: 12616157, maxGsp: 13940853 },
  { timestamp: 1701594823697, averageGsp: 12618152, maxGsp: 13943058 },
  { timestamp: 1701681268747, averageGsp: 12618463, maxGsp: 13943402 },
  { timestamp: 1701767655072, averageGsp: 12618071, maxGsp: 13942968 },
  { timestamp: 1701854047609, averageGsp: 12623610, maxGsp: 13949089 },
  { timestamp: 1701940447465, averageGsp: 12625601, maxGsp: 13951289 },
  { timestamp: 1702026854950, averageGsp: 12623033, maxGsp: 13948451 },
  { timestamp: 1702113221970, averageGsp: 12632512, maxGsp: 13958926 },
  { timestamp: 1702199623281, averageGsp: 12636878, maxGsp: 13963750 },
  { timestamp: 1702286068809, averageGsp: 12640410, maxGsp: 13967653 },
  { timestamp: 1702372449024, averageGsp: 12645527, maxGsp: 13973307 },
  { timestamp: 1702458846805, averageGsp: 12649536, maxGsp: 13977737 },
  { timestamp: 1702545242105, averageGsp: 12655699, maxGsp: 13984547 },
  { timestamp: 1702631653109, averageGsp: 12655674, maxGsp: 13984520 },
  { timestamp: 1702718025947, averageGsp: 12652270, maxGsp: 13980758 },
  { timestamp: 1702804427929, averageGsp: 12655208, maxGsp: 13984005 },
  { timestamp: 1702890855859, averageGsp: 12661950, maxGsp: 13991455 },
  { timestamp: 1702977244470, averageGsp: 12663886, maxGsp: 13993594 },
  { timestamp: 1703063615173, averageGsp: 12663525, maxGsp: 13993195 },
  { timestamp: 1703150039087, averageGsp: 12664102, maxGsp: 13993833 },
  { timestamp: 1703236452344, averageGsp: 12667054, maxGsp: 13997095 },
  { timestamp: 1703322817619, averageGsp: 12671390, maxGsp: 14001886 },
  { timestamp: 1703409242934, averageGsp: 12675076, maxGsp: 14005959 },
  { timestamp: 1703495648448, averageGsp: 12685466, maxGsp: 14017440 },
  { timestamp: 1703582035175, averageGsp: 12688671, maxGsp: 14020981 },
  { timestamp: 1703668437843, averageGsp: 12696513, maxGsp: 14029647 },
  { timestamp: 1703754856916, averageGsp: 12701589, maxGsp: 14035256 },
  { timestamp: 1703841245062, averageGsp: 12706636, maxGsp: 14040833 },
  { timestamp: 1703927621923, averageGsp: 12713315, maxGsp: 14048213 },
  { timestamp: 1704014036917, averageGsp: 12714324, maxGsp: 14049328 },
  { timestamp: 1704100455638, averageGsp: 12714257, maxGsp: 14049254 },
  { timestamp: 1704186851367, averageGsp: 12725229, maxGsp: 14061378 },
  { timestamp: 1704273273816, averageGsp: 12724825, maxGsp: 14060932 },
  { timestamp: 1704359658249, averageGsp: 12728117, maxGsp: 14064569 },
  { timestamp: 1704446042836, averageGsp: 12734213, maxGsp: 14071305 },
  { timestamp: 1704532433023, averageGsp: 12746396, maxGsp: 14084768 },
  { timestamp: 1704618827708, averageGsp: 12755879, maxGsp: 14095246 },
  { timestamp: 1704705256011, averageGsp: 12754070, maxGsp: 14093247 },
  { timestamp: 1704791648546, averageGsp: 12755323, maxGsp: 14094632 },
  { timestamp: 1704878059619, averageGsp: 12758805, maxGsp: 14098480 },
  { timestamp: 1704964457825, averageGsp: 12768468, maxGsp: 14109157 },
  { timestamp: 1705050858330, averageGsp: 12772300, maxGsp: 14113392 },
  { timestamp: 1705137233962, averageGsp: 12769422, maxGsp: 14110211 },
  { timestamp: 1705223634505, averageGsp: 12773346, maxGsp: 14114547 },
  { timestamp: 1705310063038, averageGsp: 12780093, maxGsp: 14122003 },
  { timestamp: 1705396454441, averageGsp: 12784892, maxGsp: 14127306 },
  { timestamp: 1705482859121, averageGsp: 12791028, maxGsp: 14134086 },
  { timestamp: 1705569253575, averageGsp: 12793781, maxGsp: 14137128 },
  { timestamp: 1705655653451, averageGsp: 12792997, maxGsp: 14136262 },
  { timestamp: 1705742029391, averageGsp: 12800138, maxGsp: 14144152 },
  { timestamp: 1705828434936, averageGsp: 12807578, maxGsp: 14152374 },
  { timestamp: 1705914875888, averageGsp: 12809607, maxGsp: 14154616 },
  { timestamp: 1706001271567, averageGsp: 12807979, maxGsp: 14152817 },
  { timestamp: 1706087656337, averageGsp: 12810373, maxGsp: 14155462 },
  { timestamp: 1706174049566, averageGsp: 12809985, maxGsp: 14155033 },
  { timestamp: 1706260452273, averageGsp: 12818919, maxGsp: 14164905 },
  { timestamp: 1706346822177, averageGsp: 12822839, maxGsp: 14169237 },
  { timestamp: 1706433238643, averageGsp: 12820865, maxGsp: 14167056 },
  { timestamp: 1706519644034, averageGsp: 12826653, maxGsp: 14173452 },
  { timestamp: 1706606052478, averageGsp: 12834354, maxGsp: 14181961 },
  { timestamp: 1706692429181, averageGsp: 12836799, maxGsp: 14184663 },
  { timestamp: 1706778853150, averageGsp: 12844115, maxGsp: 14192747 },
  { timestamp: 1706865254685, averageGsp: 12845468, maxGsp: 14194242 },
  { timestamp: 1706951650794, averageGsp: 12845841, maxGsp: 14194654 },
  { timestamp: 1707038034685, averageGsp: 12850961, maxGsp: 14200312 },
  { timestamp: 1707124459736, averageGsp: 12858972, maxGsp: 14209164 },
  { timestamp: 1707210842579, averageGsp: 12867175, maxGsp: 14218228 },
  { timestamp: 1707297256240, averageGsp: 12868509, maxGsp: 14219702 },
  { timestamp: 1707383651673, averageGsp: 12870645, maxGsp: 14222063 },
  { timestamp: 1707470051085, averageGsp: 12872612, maxGsp: 14224236 },
  { timestamp: 1707556431757, averageGsp: 12874363, maxGsp: 14226171 },
  { timestamp: 1707642832992, averageGsp: NaN, maxGsp: NaN },
  { timestamp: 1707729259123, averageGsp: 12882142, maxGsp: 14234767 },
  { timestamp: 1707815647374, averageGsp: 12876327, maxGsp: 14228341 },
  { timestamp: 1707902059838, averageGsp: 12878898, maxGsp: 14231182 },
  { timestamp: 1707988492899, averageGsp: 12881012, maxGsp: 14233518 },
  { timestamp: 1708074830842, averageGsp: 12882327, maxGsp: 14234971 },
  { timestamp: 1708161219451, averageGsp: 12888306, maxGsp: 14241578 },
  { timestamp: 1708247644400, averageGsp: 12890420, maxGsp: 14243914 },
  { timestamp: 1708334042617, averageGsp: 12892874, maxGsp: 14246626 },
  { timestamp: 1708420434430, averageGsp: 12903355, maxGsp: 14258207 },
  { timestamp: 1708506870987, averageGsp: 12907749, maxGsp: 14263063 },
  { timestamp: 1708593253794, averageGsp: 12910318, maxGsp: 14265901 },
  { timestamp: 1708679633892, averageGsp: 12914152, maxGsp: 14270138 },
  { timestamp: 1708766018171, averageGsp: 12917483, maxGsp: 14273819 },
  { timestamp: 1708852424947, averageGsp: 12919888, maxGsp: 14276476 },
  { timestamp: 1708938869061, averageGsp: 12922031, maxGsp: 14278844 },
  { timestamp: 1709025289183, averageGsp: 12923687, maxGsp: 14280674 },
  { timestamp: 1709111643162, averageGsp: 12927284, maxGsp: 14284649 },
  { timestamp: 1709198063502, averageGsp: 12927852, maxGsp: 14285276 },
  { timestamp: 1709284475364, averageGsp: 12931117, maxGsp: 14288884 },
  { timestamp: 1709370812140, averageGsp: 12935837, maxGsp: 14294100 },
  { timestamp: 1709457240767, averageGsp: NaN, maxGsp: NaN },
  { timestamp: 1709546283291, averageGsp: 12950521, maxGsp: 14310326 },
  { timestamp: 1709630033324, averageGsp: 12954771, maxGsp: 14315022 },
  { timestamp: 1709716443199, averageGsp: 12961570, maxGsp: 14322535 },
  { timestamp: 1709802833902, averageGsp: 12961951, maxGsp: 14322956 },
  { timestamp: 1709889236241, averageGsp: 12964052, maxGsp: 14325277 },
  { timestamp: 1709975665007, averageGsp: 12967289, maxGsp: 14328854 },
  { timestamp: 1710062053454, averageGsp: 12974600, maxGsp: 14336933 },
  { timestamp: 1710148462649, averageGsp: 12974371, maxGsp: 14336680 },
  { timestamp: 1710234905899, averageGsp: 12976165, maxGsp: 14338662 },
  { timestamp: 1710321254675, averageGsp: 12971148, maxGsp: 14333119 },
  { timestamp: 1710407648877, averageGsp: 12975388, maxGsp: 14337804 },
  { timestamp: 1710494033052, averageGsp: 12976412, maxGsp: 14338935 },
  { timestamp: 1710580419310, averageGsp: 12971575, maxGsp: 14333590 },
  { timestamp: 1710666814393, averageGsp: 12973120, maxGsp: 14335298 },
  { timestamp: 1710753252803, averageGsp: 12973930, maxGsp: 14336193 },
  { timestamp: 1710839643635, averageGsp: 12973105, maxGsp: 14335281 },
  { timestamp: 1710926071486, averageGsp: 12981834, maxGsp: 14344927 },
  { timestamp: 1711012455255, averageGsp: 12985404, maxGsp: 14348871 },
  { timestamp: 1711098881573, averageGsp: 12984008, maxGsp: 14347329 },
  { timestamp: 1711185236886, averageGsp: 12987762, maxGsp: 14351477 },
  { timestamp: 1711271658821, averageGsp: 12994351, maxGsp: 14358758 },
  { timestamp: 1711358051804, averageGsp: 12999032, maxGsp: 14363930 },
  { timestamp: 1711444463628, averageGsp: 13000188, maxGsp: 14365208 },
  { timestamp: 1711530862170, averageGsp: 12999790, maxGsp: 14364768 },
  { timestamp: 1711617266754, averageGsp: 13007885, maxGsp: 14373713 },
  { timestamp: 1711703658097, averageGsp: 13013273, maxGsp: 14379667 },
  { timestamp: 1711790035852, averageGsp: 13009251, maxGsp: 14375222 },
  { timestamp: 1711876445526, averageGsp: 13008174, maxGsp: 14374032 },
  { timestamp: 1711962874854, averageGsp: 13013499, maxGsp: 14379916 },
  { timestamp: 1712049256533, averageGsp: 13014889, maxGsp: 14381452 },
  { timestamp: 1712135654799, averageGsp: 13016616, maxGsp: 14383361 },
  { timestamp: 1712222052065, averageGsp: 13016759, maxGsp: 14383519 },
  { timestamp: 1712308443283, averageGsp: 13017488, maxGsp: 14384324 },
  { timestamp: 1712394835296, averageGsp: 13025393, maxGsp: 14393059 },
  { timestamp: 1712481234842, averageGsp: 13030699, maxGsp: 14398922 },
  { timestamp: 1712567659345, averageGsp: 13033804, maxGsp: 14402353 },
  { timestamp: 1712654049673, averageGsp: 13033208, maxGsp: 14401695 },
  { timestamp: 1712740486244, averageGsp: 13034448, maxGsp: 14403065 },
  { timestamp: 1712826866615, averageGsp: 13037708, maxGsp: 14406667 },
  { timestamp: 1712913254447, averageGsp: 13039937, maxGsp: 14409130 },
  { timestamp: 1712999623691, averageGsp: 13046161, maxGsp: 14416008 },
  { timestamp: 1713090596100, averageGsp: 13057032, maxGsp: 14428020 },
  { timestamp: 1713172433598, averageGsp: 13057846, maxGsp: 14428920 },
  { timestamp: 1713258850600, averageGsp: 13061839, maxGsp: 14433332 },
  { timestamp: 1713345258239, averageGsp: 13069914, maxGsp: 14442255 },
  { timestamp: 1713431655482, averageGsp: 13075584, maxGsp: 14448520 },
  { timestamp: 1713518077335, averageGsp: 13079633, maxGsp: 14452994 },
  { timestamp: 1713604431723, averageGsp: 13076208, maxGsp: 14449210 },
  { timestamp: 1713690857949, averageGsp: 13086211, maxGsp: 14460263 },
  { timestamp: 1713777271313, averageGsp: 13089164, maxGsp: 14463526 },
  { timestamp: 1713863650743, averageGsp: 13090050, maxGsp: 14464505 },
  { timestamp: 1713950068147, averageGsp: 13087340, maxGsp: 14461511 },
  { timestamp: 1714036473308, averageGsp: 13083736, maxGsp: 14457528 },
  { timestamp: 1714122870729, averageGsp: 13075647, maxGsp: 14448590 },
  { timestamp: 1714209245116, averageGsp: 13079296, maxGsp: 14452622 },
  { timestamp: 1714295645129, averageGsp: 13090567, maxGsp: 14465077 },
  { timestamp: 1714382085912, averageGsp: 13094787, maxGsp: 14469740 },
  { timestamp: 1714468475432, averageGsp: 13096386, maxGsp: 14471507 },
  { timestamp: 1714554883413, averageGsp: 13103461, maxGsp: 14479324 },
  { timestamp: 1714641257942, averageGsp: 13111853, maxGsp: 14488598 },
  { timestamp: 1714727667159, averageGsp: 13114897, maxGsp: 14491961 },
  { timestamp: 1714814064189, averageGsp: 13114313, maxGsp: 14491316 },
  { timestamp: 1714900481740, averageGsp: 13113820, maxGsp: 14490771 },
  { timestamp: 1714986875275, averageGsp: 13118794, maxGsp: 14496267 },
  { timestamp: 1715073282337, averageGsp: 13122158, maxGsp: 14499985 },
  { timestamp: 1715159673453, averageGsp: 13116852, maxGsp: 14494121 },
  { timestamp: 1715246068832, averageGsp: 13113840, maxGsp: 14490793 },
  { timestamp: 1715332467345, averageGsp: 13114030, maxGsp: 14491003 },
  { timestamp: 1715418888027, averageGsp: 13114474, maxGsp: 14491494 },
  { timestamp: 1715505241132, averageGsp: 13119347, maxGsp: 14496878 },
  { timestamp: 1715591667824, averageGsp: 13121710, maxGsp: 14499490 },
  { timestamp: 1715678077695, averageGsp: 13125887, maxGsp: 14504105 },
  { timestamp: 1715764513876, averageGsp: 13133728, maxGsp: 14512769 },
  { timestamp: 1715850866674, averageGsp: 13134465, maxGsp: 14513584 },
  { timestamp: 1715937293282, averageGsp: 13139036, maxGsp: 14518635 },
  { timestamp: 1716023665238, averageGsp: 13145404, maxGsp: 14525671 },
  { timestamp: 1716110075991, averageGsp: 13146449, maxGsp: 14526826 },
  { timestamp: 1716196513853, averageGsp: 13148025, maxGsp: 14528568 },
  { timestamp: 1716282887595, averageGsp: 13148589, maxGsp: 14529191 },
  { timestamp: 1716369283537, averageGsp: 13146146, maxGsp: 14526491 },
  { timestamp: 1716455694235, averageGsp: 13147420, maxGsp: 14527899 },
  { timestamp: 1716542078002, averageGsp: 13148492, maxGsp: 14529084 },
  { timestamp: 1716628468683, averageGsp: 13148772, maxGsp: 14529393 },
  { timestamp: 1716714902022, averageGsp: 13148551, maxGsp: 14529149 },
  { timestamp: 1716801286774, averageGsp: 13160029, maxGsp: 14541832 },
  { timestamp: 1716887679576, averageGsp: 13164300, maxGsp: 14546552 },
  { timestamp: 1716974088807, averageGsp: 13165187, maxGsp: 14547532 },
  { timestamp: 1717060489085, averageGsp: 13175514, maxGsp: 14558943 },
  { timestamp: 1717146879727, averageGsp: 13173598, maxGsp: 14556826 },
  { timestamp: 1717233293116, averageGsp: 13169943, maxGsp: 14552787 },
  { timestamp: 1717319676116, averageGsp: 13178383, maxGsp: 14562113 },
  { timestamp: 1717406072910, averageGsp: 13179976, maxGsp: 14563873 },
  { timestamp: 1717492489790, averageGsp: 13179580, maxGsp: 14563436 },
  { timestamp: 1717578909598, averageGsp: 13179028, maxGsp: 14562826 },
  { timestamp: 1717665292062, averageGsp: 13181631, maxGsp: 14565702 },
  { timestamp: 1717751685386, averageGsp: 13185677, maxGsp: 14570173 },
  { timestamp: 1717838099267, averageGsp: 13189026, maxGsp: 14573874 },
  { timestamp: 1717924475425, averageGsp: 13188937, maxGsp: 14573775 },
  { timestamp: 1718010896825, averageGsp: 13188540, maxGsp: 14573337 },
  { timestamp: 1718097291112, averageGsp: 13197741, maxGsp: 14583504 },
  { timestamp: 1718183683305, averageGsp: 13199671, maxGsp: 14585636 },
  { timestamp: 1718270100841, averageGsp: 13198530, maxGsp: 14584376 },
  { timestamp: 1718356475264, averageGsp: 13203135, maxGsp: 14589464 },
  { timestamp: 1718442857773, averageGsp: 13205909, maxGsp: 14592529 },
  { timestamp: 1718529287181, averageGsp: 13212471, maxGsp: 14599780 },
  { timestamp: 1718615683499, averageGsp: 13214991, maxGsp: 14602565 },
  { timestamp: 1718702085157, averageGsp: 13215533, maxGsp: 14603164 },
  { timestamp: 1718788512905, averageGsp: 13215373, maxGsp: 14602987 },
  { timestamp: 1718874892267, averageGsp: 13215783, maxGsp: 14603440 },
  { timestamp: 1718961272356, averageGsp: 13222871, maxGsp: 14611272 },
  { timestamp: 1719047672808, averageGsp: 13227286, maxGsp: 14616151 },
  { timestamp: 1719134075116, averageGsp: 13228379, maxGsp: 14617359 },
  { timestamp: 1719220499673, averageGsp: 13233046, maxGsp: 14622516 },
  { timestamp: 1719306882600, averageGsp: 13232377, maxGsp: 14621777 },
  { timestamp: 1719393297669, averageGsp: 13235383, maxGsp: 14625098 },
  { timestamp: 1719479675172, averageGsp: 13240215, maxGsp: 14630438 },
  { timestamp: 1719566077205, averageGsp: 13236434, maxGsp: 14626260 },
  { timestamp: 1719652482623, averageGsp: 13237572, maxGsp: 14627517 },
  { timestamp: 1719738907135, averageGsp: 13240155, maxGsp: 14630371 },
  { timestamp: 1719825312863, averageGsp: 13236540, maxGsp: 14626377 },
  { timestamp: 1719911699073, averageGsp: 13239715, maxGsp: 14629885 },
  { timestamp: 1719998075497, averageGsp: NaN, maxGsp: NaN },
  { timestamp: 1720084485999, averageGsp: 13247497, maxGsp: 14638484 },
  { timestamp: 1720170890327, averageGsp: 13255335, maxGsp: 14647145 },
  { timestamp: 1720257274292, averageGsp: 13260777, maxGsp: 14653159 },
  { timestamp: 1720343682840, averageGsp: 13264225, maxGsp: 14656969 },
  { timestamp: 1720430094241, averageGsp: 13270416, maxGsp: 14663810 },
  { timestamp: 1720516480168, averageGsp: 13275347, maxGsp: 14669258 },
  { timestamp: 1720602877219, averageGsp: 13284753, maxGsp: 14679652 },
  { timestamp: 1720689294417, averageGsp: 13274103, maxGsp: 14667884 },
  { timestamp: 1720775699839, averageGsp: 13270632, maxGsp: 14664048 },
  { timestamp: 1720862066629, averageGsp: 13272593, maxGsp: 14666215 },
  { timestamp: 1720948389160, averageGsp: 13274315, maxGsp: 14668118 },
  { timestamp: 1721034903696, averageGsp: 13280806, maxGsp: 14675291 },
  { timestamp: 1721121297027, averageGsp: 13279780, maxGsp: 14674157 },
  { timestamp: 1721207700252, averageGsp: 13281433, maxGsp: 14675983 },
  { timestamp: 1721294078927, averageGsp: 13289461, maxGsp: 14684854 },
  { timestamp: 1721380491792, averageGsp: 13291578, maxGsp: 14687194 },
  { timestamp: 1721466862503, averageGsp: 13293348, maxGsp: 14689150 },
  { timestamp: 1721553265632, averageGsp: 13297249, maxGsp: 14693460 },
  { timestamp: 1721639727798, averageGsp: 13299913, maxGsp: 14696404 },
  { timestamp: 1721726123259, averageGsp: 13303388, maxGsp: 14700244 },
  { timestamp: 1721812477706, averageGsp: 13298303, maxGsp: 14694625 },
  { timestamp: 1721898896194, averageGsp: 13301960, maxGsp: 14698666 },
  { timestamp: 1721985272886, averageGsp: 13300224, maxGsp: 14696748 },
  { timestamp: 1722071676397, averageGsp: 13300951, maxGsp: 14697551 },
  { timestamp: 1722158092162, averageGsp: 13306668, maxGsp: 14703868 },
  { timestamp: 1722244496716, averageGsp: 13302797, maxGsp: 14699591 },
  { timestamp: 1722330884180, averageGsp: 13300745, maxGsp: 14697323 },
  { timestamp: 1722417317789, averageGsp: 13310692, maxGsp: 14708315 },
  { timestamp: 1722503689881, averageGsp: 13309396, maxGsp: 14706883 },
  { timestamp: 1722590096826, averageGsp: 13315517, maxGsp: 14713646 },
  { timestamp: 1722762862193, averageGsp: 13322693, maxGsp: 14721576 },
  { timestamp: 1722849295130, averageGsp: 13329878, maxGsp: 14729515 },
  { timestamp: 1722935680881, averageGsp: 13334512, maxGsp: 14734636 },
  { timestamp: 1723022089651, averageGsp: 13333595, maxGsp: 14733622 },
  { timestamp: 1723108482794, averageGsp: 13341245, maxGsp: 14742076 },
  { timestamp: 1723194900268, averageGsp: 13353683, maxGsp: 14755820 },
  { timestamp: 1723281277161, averageGsp: 13358602, maxGsp: 14761255 },
  { timestamp: 1723367676591, averageGsp: 13356710, maxGsp: 14759165 },
  { timestamp: 1723454096907, averageGsp: 13358966, maxGsp: 14761657 },
  { timestamp: 1723540518169, averageGsp: 13365456, maxGsp: 14768829 },
  { timestamp: 1723626885151, averageGsp: 13364824, maxGsp: 14768131 },
  { timestamp: 1723713288109, averageGsp: 13366259, maxGsp: 14769716 },
  { timestamp: 1723799702411, averageGsp: 13360750, maxGsp: 14763629 },
  { timestamp: 1723886056707, averageGsp: 13361444, maxGsp: 14764396 },
  { timestamp: 1723972498915, averageGsp: 13369531, maxGsp: 14773332 },
  { timestamp: 1724058896372, averageGsp: 13372888, maxGsp: 14777041 },
  { timestamp: 1724145301070, averageGsp: 13374108, maxGsp: 14778389 },
  { timestamp: 1724231699179, averageGsp: 13375500, maxGsp: 14779928 },
  { timestamp: 1724318083920, averageGsp: 13379479, maxGsp: 14784324 },
  { timestamp: 1724404475711, averageGsp: 13385335, maxGsp: 14790795 },
  { timestamp: 1724490867697, averageGsp: 13389184, maxGsp: 14795048 },
  { timestamp: 1724577266015, averageGsp: 13389682, maxGsp: 14795599 },
  { timestamp: 1724663690516, averageGsp: 13397642, maxGsp: 14804394 },
  { timestamp: 1724750088450, averageGsp: 13398541, maxGsp: 14805388 },
  // END DATA
];
